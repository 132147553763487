import { Link as RouterLink } from 'react-router-dom';
import { Box, Stack, Typography } from '@material-ui/core';
import Logo from 'src/components/Logo';
import { MHidden } from '../../components/@material-extend';
import IconCollapse from './IconCollapse';

export default function AdminSidebarWrapper({
  showPageTitle = false,
  pageTitle,
  path,
  isCollapse,
  showOnCollapse = true,
  collapseClick,
  onToggleCollapse,
  sx,
  headerSx,
  children
}) {
  const showChildren = isCollapse ? showOnCollapse : true;
  return (
    <Stack
      spacing={2}
      sx={{
        ...(isCollapse && {
          alignItems: 'center'
        }),
        ...sx
      }}
    >
      <Stack
        direction={isCollapse ? 'column' : 'row'}
        spacing={3}
        alignItems="center"
        justifyContent="space-between"
        sx={{ ...headerSx }}
      >
        {showPageTitle ? (
          <Typography variant="h6" color="common.white">
            {pageTitle}
          </Typography>
        ) : (
          <Box {...(path && { component: RouterLink, to: path })}>
            <Logo
              logo={{
                variant: 'secondary-light',
                contrast: 'secondary'
              }}
              isLogo
              sx={{ ...(!isCollapse && { marginLeft: 1.75 }) }}
            />
          </Box>
        )}

        <MHidden width="lgDown">
          {!isCollapse && <IconCollapse onToggleCollapse={onToggleCollapse} collapseClick={collapseClick} />}
        </MHidden>
      </Stack>
      {showChildren && children}
    </Stack>
  );
}
