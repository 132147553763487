import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  loading: {
    paymentMethodLoading: false,
    currentBillingLoading: false,
    statementsLoading: false
  },
  error: {
    paymentMethodError: null,
    currentBillingError: null,
    statementsError: null
  },
  paymentMethod: null,
  currentBilling: [],
  statements: []
};

const slice = createSlice({
  name: 'billing',
  initialState,
  reducers: {
    // START LOADING
    startPaymentMethodLoading(state) {
      state.loading.paymentMethodLoading = true;
    },
    startCurrentBillingLoading(state) {
      state.loading.currentBillingLoading = true;
    },
    startStatementsLoading(state) {
      state.loading.statementsLoading = true;
    },

    // HAS ERROR
    hasPaymentMethodError(state, action) {
      state.loading.paymentMethodLoading = false;
      state.error.paymentMethodError = action.payload;
    },
    hasCurrentBillingError(state, action) {
      state.loading.currentBillingLoading = false;
      state.error.currentBillingError = action.payload;
    },
    hasStatementsError(state, action) {
      state.loading.statementsLoading = false;
      state.error.statementsError = action.payload;
    },

    // GET DATA
    getPaymentMethodSuccess(state, action) {
      state.loading.paymentMethodLoading = false;
      state.error.paymentMethodError = null;
      state.paymentMethod = action.payload;
    },
    getCurrentBillingSuccess(state, action) {
      state.loading.currentBillingLoading = false;
      state.error.currentBillingError = null;
      state.currentBilling = action.payload;
    },
    getStatementsSuccess(state, action) {
      state.loading.statementsLoading = false;
      state.error.statementsError = null;
      state.statements = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getPaymentMethod() {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startPaymentMethodLoading());
      const response = await axios.get('/manage/tenant/billing/payments');
      dispatch(slice.actions.getPaymentMethodSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasPaymentMethodError(error));
    }
  };
}

export function getCurrentBillingStatement() {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startCurrentBillingLoading());
      const response = await axios.get('/manage/tenant/statements', {
        params: {
          current: true
        }
      });
      dispatch(slice.actions.getCurrentBillingSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasCurrentBillingError(error));
    }
  };
}

export function getStatements() {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startStatementsLoading());
      const response = await axios.get('/manage/tenant/statements', {
        params: {
          current: false
        }
      });
      dispatch(slice.actions.getStatementsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasStatementsError(error));
    }
  };
}
