import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  loading: {
    currentPlanLoading: false,
    plansOptionsLoading: false,
    plansLoading: false,
    planLoading: false
  },
  error: {
    currentPlanError: null,
    plansOptionsError: null,
    plansError: null,
    planError: null
  },
  currentPlan: null,
  plan: null,
  plans: null,
  options: {
    plans: []
  }
};

const slice = createSlice({
  name: 'plan',
  initialState,
  reducers: {
    // START LOADING
    startCurrentPlanLoading(state) {
      state.loading.currentPlanLoading = true;
    },
    startPlanLoading(state) {
      state.loading.planLoading = true;
    },
    startPlansOptionsLoading(state) {
      state.loading.plansOptionsLoading = true;
    },
    startSubscriptionPlansLoading(state) {
      state.loading.plansLoading = true;
    },

    // HAS ERROR
    hasCurrentPlansError(state, action) {
      state.loading.currentPlanLoading = false;
      state.error.currentPlanError = action.payload;
    },
    hasPlanError(state, action) {
      state.loading.planLoading = false;
      state.error.planError = action.payload;
    },
    hasPlansOptionsError(state, action) {
      state.loading.plansOptionsLoading = false;
      state.error.plansOptionsError = action.payload;
    },
    hasSubscriptionPlansError(state, action) {
      state.loading.plansLoading = false;
      state.error.plansError = action.payload;
    },

    // GET DATA
    getCurrentPlanSuccess(state, action) {
      state.loading.currentPlanLoading = false;
      state.currentPlan = action.payload;
      state.error.currentPlanError = null;
    },
    getPlanSuccess(state, action) {
      state.loading.planLoading = false;
      state.plan = action.payload;
      state.error.planError = null;
    },
    getPlansOptionsSuccess(state, action) {
      state.loading.plansOptionsLoading = false;
      state.options.plans = action.payload;
      state.error.plansOptionsError = null;
    },
    getSubscriptionPlansSuccess(state, action) {
      state.loading.plansLoading = false;
      state.plans = action.payload;
      state.error.plansError = null;
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getCurrentPlan() {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startCurrentPlanLoading());
      const response = await axios.get('/manage/subscription');
      dispatch(slice.actions.getCurrentPlanSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasCurrentPlansError(error));
    }
  };
}

export const updateCurrentPlan = (id) => {
  return axios.put('/manage/subscription', {
    priceId: id
  });
};

export function getPlan(id) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startPlanLoading());
      const response = await axios.get(`/manage/plans/${id}`);
      dispatch(slice.actions.getPlanSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasPlanError(error));
    }
  };
}

export function getPlansOptions() {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startPlansOptionsLoading());
      const response = await axios.get('/website/options/plans');
      dispatch(slice.actions.getPlansOptionsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasPlansOptionsError(error));
    }
  };
}
export function getSubscriptionPlans() {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startSubscriptionPlansLoading());
      const response = await axios.get('/manage/plans');
      dispatch(slice.actions.getSubscriptionPlansSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasSubscriptionPlansError(error));
    }
  };
}
