import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  loading: {
    filesIsLoading: false
  },
  error: {
    filesError: null
  },
  files: {
    hasNext: false,
    page: 0,
    total: 0,
    data: []
  }
};

const slice = createSlice({
  name: 'file',
  initialState,
  reducers: {
    // START LOADING
    startFilesLoading(state) {
      state.loading.filesIsLoading = true;
    },

    // HAS ERROR
    hasFilesError(state, action) {
      state.loading.filesIsLoading = false;
      state.error.filesError = action.payload;
    },

    // GET PRODUCTS
    getFilesSuccess(state, action) {
      state.loading.filesIsLoading = false;
      state.error.filesError = null;
      state.files = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getFiles(page, rowsPerPage, orderBy, order, filterName) {
  return async (dispatch) => {
    dispatch(slice.actions.startFilesLoading());
    try {
      const response = await axios.get('/manage/files', {
        params: {
          page,
          size: rowsPerPage,
          orderBy,
          order,
          search: filterName
        }
      });
      dispatch(slice.actions.getFilesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasFilesError(error));
    }
  };
}

export function deleteFile(id) {
  return axios.delete(`/manage/files/${id}`);
}

export function deleteFiles(ids) {
  return axios.post(`/manage/files/batch`, null, {
    params: {
      ids: ids.toString()
    }
  });
}
