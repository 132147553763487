import { isEmpty, defaultTo } from 'lodash';
// hooks
import useAuth from '../hooks/useAuth';
import { MAvatar } from './@material-extend';
import createAvatar from '../utils/createAvatar';

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }) {
  const { user } = useAuth();
  const name = isEmpty(user?.name) ? user?.firstName : defaultTo(user?.name, '');
  const color = user?.photoURL ? 'default' : createAvatar(name).color;

  return (
    <MAvatar src={user?.photoURL} alt={name} color={color} {...other}>
      {createAvatar(name).name}
    </MAvatar>
  );
}
