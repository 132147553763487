import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  loading: {
    configuredProvidersLoading: false,
    providerOptionsLoading: false
  },
  error: {
    configuredProvidersError: null,
    providerOptionsError: null
  },
  configuredProviders: [],
  providerOptions: []
};

const slice = createSlice({
  name: 'fulfilmentProvider',
  initialState,
  reducers: {
    // START LOADING
    startConfiguredFulfilmentProviderLoading(state) {
      state.loading.configuredProvidersLoading = true;
      state.error.configuredProvidersError = null;
    },

    startFulfilmentProvidersLoading(state) {
      state.loading.providerOptionsLoading = true;
      state.error.providerOptionsError = null;
    },

    // HAS ERROR
    hasConfiguredFulfilmentProviderError(state, action) {
      state.loading.configuredProvidersLoading = false;
      state.error.configuredProvidersError = action.payload;
    },
    hasFulfilmentProvidersError(state, action) {
      state.loading.providerOptionsLoading = false;
      state.error.providerOptionsError = action.payload;
    },

    // GET SHIPPING
    getConfiguredFulfilmentProviderSuccess(state, action) {
      state.loading.configuredProvidersLoading = false;
      state.error.configuredProvidersError = null;
      state.configuredProviders = action.payload;
    },
    getFulfilmentProvidersSuccess(state, action) {
      state.loading.providerOptionsLoading = false;
      state.error.providerOptionsError = null;
      state.providerOptions = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getConfiguredFulfilmentProviders() {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startConfiguredFulfilmentProviderLoading());
      const response = await axios.get('/manage/integrations/fulfilment');
      dispatch(slice.actions.getConfiguredFulfilmentProviderSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasConfiguredFulfilmentProviderError(error));
    }
  };
}

export function getFulfilmentProviders() {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startFulfilmentProvidersLoading());
      const response = await axios.get('/manage/options/integrations/fulfilment');
      dispatch(slice.actions.getFulfilmentProvidersSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasFulfilmentProvidersError(error));
    }
  };
}
