import axios from 'src/utils/axios';

export const handleGetProducts = async (page, rowsPerPage, orderBy, order, filterName, status) => {
    return axios.get('/manage/products', {
        params: {
            page,
            size: rowsPerPage,
            orderBy,
            order,
            search: filterName,
            status
        }
    })
}