import axios from 'src/utils/axios';

export const createConfiguredPaymentMethod = async (values) => {
  await axios.post('/manage/payments', { ...values });
};

export const updateConfiguredPaymentMethod = async (id, values) => {
  await axios.put(`/manage/payments/${id}`, { ...values });
};

export const deleteConfiguredPaymentMethod = async (id) => {
  await axios.delete(`/manage/payments/${id}`);
};

export const createSubscription = async (id) => {
  const response = await axios.post(`/manage/subscription`, {
    priceId: id
  });
  return response.data;
};
