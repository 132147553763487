import PropTypes from 'prop-types';
import { Tooltip, CardActionArea } from '@material-ui/core';
import { Icon } from '@iconify/react';
import arrowLeftToLine from '@iconify/icons-lucide/arrow-left-to-line';
import arrowRightToLine from '@iconify/icons-lucide/arrow-right-to-line';

IconCollapse.propTypes = {
  onToggleCollapse: PropTypes.func,
  collapseClick: PropTypes.bool
};

export default function IconCollapse({ onToggleCollapse, collapseClick: open }) {
  return (
    <Tooltip title={!open ? 'Collapse' : 'Expand'}>
      <CardActionArea
        onClick={onToggleCollapse}
        sx={{
          width: 40,
          height: 40,
          display: 'flex',
          borderRadius: '50%'
        }}
      >
        <Icon fontSize={20} icon={open ? arrowRightToLine : arrowLeftToLine} />
      </CardActionArea>
    </Tooltip>
  );
}
