import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
import eyeFill from '@iconify/icons-eva/eye-fill';
import { useSelector } from 'src/redux/store';
import { deployStoreConfiguration } from 'src/redux/slices/store';
import { useSnackbar } from 'notistack5';
import { isDepsLoading } from 'src/pages/admin_v2/editor';
import { useNavigate } from 'react-router-dom';
import baselineRocket from '@iconify/icons-ic/baseline-rocket';
import { alpha, styled } from '@material-ui/core/styles';
import useCollapseDrawer from 'src/hooks/useCollapseDrawer';
import { Tooltip, Link, Box, Stack, AppBar, Toolbar, IconButton, Typography } from '@material-ui/core';
import { PATH_DASHBOARD } from 'src/routes/paths';
import GenericAlertDialog from 'src/components/_admin_v2/GenericAlertDialog';
import DeployStoreDialoag from 'src/components/_admin_v2/editor/DeployStoreDialoag';
import useOffSetTop from 'src/hooks/useOffSetTop';
import { MAvatar, MFab, MHidden } from '../../components/@material-extend';
import { APPBAR_DESKTOP_MD, APPBAR_MOBILE, COLLAPSE_WIDTH, DRAWER_WIDTH } from './constants';

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH}px)`
  }
}));

const IconStyle = styled(Icon)(({ theme }) => ({
  color: theme.palette.common.white,
  width: 25,
  height: 25
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP_MD,
    padding: theme.spacing(0, 5)
  }
}));

AdminStoreEditorNavbar.propTypes = {
  onOpenSidebar: PropTypes.func
};

export default function AdminStoreEditorNavbar({ onOpenSidebar }) {
  const {
    loading,
    editor: { theme: storeTheme }
  } = useSelector((state) => state.store);
  const { tenant } = useSelector((state) => state.tenant);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const isOffset = useOffSetTop(0);
  const { isCollapse } = useCollapseDrawer();
  const [open, setOpen] = useState(false);
  const [deployOpen, setDeployOpen] = useState(false);
  const [deployLoading, setDeployLoading] = useState(false);
  const [isStoreLoading, setIsStoreLoading] = useState(true);
  const storeUrl = tenant?.storeUrl;

  useEffect(() => {
    setIsStoreLoading(isDepsLoading(loading));
  }, [loading]);

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleDeployOpenDialog = () => {
    setDeployOpen(true);
  };

  const handleDeployCloseDialog = () => {
    setDeployOpen(false);
  };

  const handlDeployStoreConfiguration = (exitAfter = true) => {
    setDeployLoading(true);
    deployStoreConfiguration(storeTheme)
      .then(() => {
        setDeployLoading(false);
        if (exitAfter) {
          navigate(PATH_DASHBOARD.store.root);
        } else {
          handleDeployCloseDialog();
          enqueueSnackbar('Changes Deployed', {
            variant: 'success',
            preventDuplicate: true,
            anchorOrigin: { vertical: 'top', horizontal: 'center' }
          });
        }
      })
      .catch(() => {
        setDeployLoading(false);
        enqueueSnackbar('Something went wrong. Please try again shortly', {
          variant: 'error',
          preventDuplicate: true,
          anchorOrigin: { vertical: 'top', horizontal: 'center' }
        });
      });
  };

  const handleExit = () => {
    navigate(PATH_DASHBOARD.store.root);
  };

  const ExitEditorDialog = (
    <GenericAlertDialog
      open={open}
      title="Leave Store Editor?"
      description="You are leaving the store editor. If you have any unsaved changes they will be lost. "
      okayText="Leave"
      handleClose={handleCloseDialog}
      handleOkay={handleExit}
    />
  );

  const DeployConfiDialog = (
    <DeployStoreDialoag
      open={deployOpen}
      loading={deployLoading}
      handleClose={handleDeployCloseDialog}
      handleDeployAndExit={handlDeployStoreConfiguration}
      handleDeploy={() => handlDeployStoreConfiguration(false)}
    />
  );

  const VisitStoreButton = storeUrl && (
    <MHidden width="smDown">
      <Tooltip title="Visit Store" enterDelay={0}>
        <MFab
          component={Link}
          variant="extended"
          size="medium"
          color="warning"
          href={storeUrl}
          target="_blank"
          disabled={isStoreLoading || deployLoading}
          sx={{
            svg: {
              mr: 0,
              ml: 0
            },
            width: 40
          }}
        >
          <MAvatar color="warning" sx={{ padding: (theme) => theme.spacing(1.2) }}>
            <Icon fontSize={30} icon={eyeFill} />
          </MAvatar>
        </MFab>
      </Tooltip>
    </MHidden>
  );

  return (
    <RootStyle
      sx={{
        ...(isOffset && {
          boxShadow: (theme) => theme.customShadows.z8
        }),
        ...(isCollapse && {
          width: { lg: `calc(100% - ${COLLAPSE_WIDTH}px)` }
        })
      }}
    >
      {DeployConfiDialog}
      {ExitEditorDialog}

      <ToolbarStyle>
        <MHidden width="lgUp">
          <IconButton onClick={onOpenSidebar} sx={{ mr: 2, color: 'text.primary' }}>
            <Icon icon={menu2Fill} />
          </IconButton>
        </MHidden>

        <Typography variant="h3" color="primary" sx={{ userSelect: 'none', fontFamily: 'Caveat' }}>
          Deisgn Canvas
        </Typography>

        <Box sx={{ flexGrow: 1 }} />

        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          {VisitStoreButton}
          <MFab
            disabled={isStoreLoading || deployLoading}
            onClick={handleDeployOpenDialog}
            variant="extended"
            size="medium"
            color="primary"
            sx={{
              svg: {
                mr: 0,
                ml: 0.5
              }
            }}
          >
            Deploy
            <IconStyle icon={baselineRocket} />
          </MFab>

          <MFab
            disabled={isStoreLoading || deployLoading}
            onClick={handleOpenDialog}
            variant="extended"
            size="medium"
            color="secondary"
          >
            Exit
          </MFab>
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
