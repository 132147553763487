import { useState } from 'react';
import { defaultTo, isNull } from 'lodash';
import { paramCase } from 'change-case';
import { Alert, Box, Button, Container, Stack } from '@material-ui/core';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { isAfter, formatDistanceToNow } from 'date-fns';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { useSelector } from '../redux/store';

const SubscriptionStateBanner = ({ show, value }) => {
  const [showBanner, setShowBanner] = useState(show);
  return (
    showBanner && (
      <Box sx={{ py: 1, mb: 3 }}>
        <Alert
          severity="warning"
          onClose={() => setShowBanner((prev) => !prev)}
          sx={{
            alignItems: 'center',
            '& .MuiAlert-message': {
              width: '100%'
            }
          }}
        >
          <Stack
            spacing={1}
            direction="row"
            justifyContent="space-between"
            sx={{ width: '100%', alignItems: 'center' }}
          >
            <Box>{`You have ${value} left of your free trial`}</Box>
            <Box>
              <Button
                size="small"
                color="inherit"
                variant="outlined"
                LinkComponent={RouterLink}
                to={PATH_DASHBOARD.plans.root}
                sx={{ minWidth: '120px' }}
              >
                Choose A Plan
              </Button>
            </Box>
          </Stack>
        </Alert>
      </Box>
    )
  );
};

export default function SubscriptionPlanTrialNotification() {
  const { tenant } = useSelector((state) => state.tenant);
  const plan = tenant?.plan;
  const trialEndDate = plan?.trialEndsAt && new Date(plan?.trialEndsAt);
  const isTrialEndDateFuture = trialEndDate && isAfter(trialEndDate, new Date());

  const { pathname } = useLocation();
  const isReportOverviewPage = pathname?.includes('reports/overview');

  const isTrialOnlyAccount = Boolean(
    paramCase(defaultTo(plan?.type, '')) === 'trial' && isNull(plan?.startsAt) && isTrialEndDateFuture
  );

  const timeToNow = trialEndDate && formatDistanceToNow(trialEndDate, { includeSeconds: false });

  return (
    <Container maxWidth={isReportOverviewPage ? 'xl' : 'lg'}>
      <SubscriptionStateBanner show={isTrialOnlyAccount} value={timeToNow} />
    </Container>
  );
}
