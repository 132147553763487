import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Stack,
  FormHelperText,
  InputAdornment,
  Collapse,
  Box,
  Typography
} from '@material-ui/core';
import { defaultTo, isUndefined, isNull } from 'lodash';
import { LoadingButton } from '@material-ui/lab';
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import FormCheckBox from './FormCheckBox';
import { CapabilityShippingRate } from './shipping/CapabilityShippingRate';

const TieredShippingRate = ({ symbol, form }) => {
  const { getFieldProps, touched, errors } = form;
  return (
    <>
      <FormCheckBox
        touched={touched}
        errors={errors}
        {...getFieldProps('enableCondition')}
        description="You can conditionally control which shipping rates a customer can choose. The below values are the thresholds based on a customers total shopping cart value (excluding discounts applied)."
      />
      <Collapse in={getFieldProps('enableCondition').value}>
        <Stack spacing={4}>
          <Box>
            <Typography variant="overline" sx={{ color: 'primary.main' }}>
              Shopping Cart
            </Typography>
            <FormHelperText>
              In addition to setting <strong>both</strong> values, you can choose to set <strong>only</strong> a min{' '}
              <strong>OR</strong> max value
            </FormHelperText>
          </Box>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} sx={{ marginTop: 2 }}>
            <TextField
              label="Min"
              type="number"
              InputProps={{
                startAdornment: <InputAdornment position="start">{symbol}</InputAdornment>
              }}
              {...getFieldProps('minAmount')}
              error={Boolean(touched.minAmount && errors.minAmount)}
              helperText={touched.minAmount && errors.minAmount}
            />
            <TextField
              label="Max"
              type="number"
              InputProps={{
                startAdornment: <InputAdornment position="start">{symbol}</InputAdornment>
              }}
              {...getFieldProps('maxAmount')}
              error={Boolean(touched.maxAmount && errors.maxAmount)}
              helperText={touched.maxAmount && errors.maxAmount}
            />
          </Stack>
        </Stack>
      </Collapse>
    </>
  );
};

export default function ShippingRateDialog({
  isAuthorized = false,
  currency,
  shippingRate,
  open,
  onClose,
  handleSave,
  handleSaveError
}) {
  const { symbol } = currency;

  const ShippingRateSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    enabled: Yup.boolean().required('Status is required'),
    rate: Yup.string().typeError('Price must be a valid amount').required('Price is required'),
    enableCondition: Yup.boolean().required('Condition status is required'),
    minAmount: Yup.string().when('enableCondition', {
      is: true,
      then: Yup.string().when(['maxAmount'], (maxAmount, schema) => {
        if (isNull(maxAmount) || isUndefined(maxAmount)) {
          return schema.required('Minimum amount is required');
        }
        return schema;
      })
    }),
    maxAmount: Yup.string().when('enableCondition', {
      is: true,
      then: Yup.string().when(['minAmount'], (minAmount, schema) => {
        if (isNull(minAmount) || isUndefined(minAmount)) {
          return schema.required('Maximum amount is required');
        }
        return schema;
      })
    })
  });

  const formik = useFormik({
    enableReinitialize: false,
    initialValues: {
      id: defaultTo(shippingRate?.id, ''),
      name: defaultTo(shippingRate?.name, ''),
      description: defaultTo(shippingRate?.description, ''),
      enabled: defaultTo(shippingRate?.enabled, true),
      rate: defaultTo(shippingRate?.rate, ''),
      enableCondition: defaultTo(shippingRate?.enableCondition, false),
      minAmount: defaultTo(shippingRate?.minAmount, ''),
      maxAmount: defaultTo(shippingRate?.maxAmount, '')
    },

    validationSchema: ShippingRateSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        await handleSave(values);
        setSubmitting(false);
      } catch (error) {
        handleSaveError();
        setSubmitting(false);
      }
    }
  });

  const { isSubmitting, handleSubmit, getFieldProps, touched, errors, resetForm } = formik;

  const handleClose = () => {
    resetForm();
    onClose();
  };

  return (
    <Dialog fullWidth open={open} onClose={handleClose} scroll="body">
      <FormikProvider value={formik}>
        <Form autoComplete="off" onSubmit={handleSubmit}>
          <DialogTitle sx={{ pb: 2 }}>Shipping Rate</DialogTitle>
          <DialogContent dividers>
            <Stack direction="column" spacing={2}>
              <TextField
                fullWidth
                label="Name"
                {...getFieldProps('name')}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
              />
              <TextField
                fullWidth
                multiline
                rows={2}
                autoComplete="off"
                label="Description"
                {...getFieldProps('description')}
                error={Boolean(touched.description && errors.description)}
                helperText={touched.description && errors.description}
              />
              <FormCheckBox
                touched={touched}
                errors={errors}
                {...getFieldProps('enabled')}
                description="This will control whether this shipping rate can appear on your store"
              />
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <TextField
                  label="Price"
                  type="number"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">{symbol}</InputAdornment>
                  }}
                  {...getFieldProps('rate')}
                  error={Boolean(touched.rate && errors.rate)}
                  helperText={touched.rate && errors.rate}
                />
                <div />
              </Stack>
              {isAuthorized ? <TieredShippingRate symbol={symbol} form={formik} /> : <CapabilityShippingRate />}
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button disabled={isSubmitting} onClick={handleClose}>
              Cancel
            </Button>
            <LoadingButton loading={isSubmitting} type="submit" variant="contained">
              Save
            </LoadingButton>
          </DialogActions>
        </Form>
      </FormikProvider>
    </Dialog>
  );
}
