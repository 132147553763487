import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// guards
import RoleBasedGuard from 'src/guards/RoleBasedGuard';
import SubscriptionGuard from 'src/guards/SubscriptionGuard';
import { AdminLayout, AdminSettingsLayout } from 'src/layouts/admin';
import AuthGuard from '../guards/AuthGuard';
import GuestGuard from '../guards/GuestGuard';
// layouts
import MainLayout from '../layouts/main';
import AdminStoreEditorLayout from '../layouts/admin-store';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isAdmin = pathname.includes('/admin');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isAdmin && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: '*',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        },
        {
          path: 'signup',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          )
        },
        {
          path: 'reset-password',
          element: (
            <GuestGuard>
              <ResetPassword />
            </GuestGuard>
          )
        }
      ]
    },

    // Admin Routes
    {
      path: 'admin',
      element: (
        <AuthGuard>
          <SubscriptionGuard>
            <AdminLayout />
          </SubscriptionGuard>
        </AuthGuard>
      ),
      children: [
        { path: '', element: <Navigate to="/admin/orders" replace /> },

        // Admin routes v2
        { path: 'account', element: <UserAccount /> },
        { path: 'orders', element: <OrderList /> },
        { path: 'orders/:id', element: <Order /> },
        { path: 'customers', element: <CustomerList /> },
        { path: 'customers/:id', element: <Customer /> },
        { path: 'discounts', element: <DiscountList /> },
        { path: 'discounts/create', element: <Discount /> },
        { path: 'discounts/:code', element: <Discount /> },
        { path: 'products', element: <ProductList /> },
        { path: 'products/import', element: <ProductsImport /> },
        { path: 'products/:id', element: <Product /> },
        { path: 'inventory', element: <InventoryList /> },
        { path: 'collections', element: <ProductGroupList /> },
        { path: 'collections/:id', element: <ProductGroup /> },
        { path: 'collections/create', element: <ProductGroup /> },
        {
          path: 'reports',
          children: [
            { path: '', element: <ReportList /> },
            { path: 'overview', element: <ReportOverview /> },
            { path: ':id', element: <Report /> }
          ]
        },
        {
          path: 'plans',
          element: <RoleBasedGuard accessibleRoles={['admin']} />,
          children: [
            { path: '', element: <PlansChoosePlan /> },
            { path: ':id/payment', element: <PlansPayment /> },
            { path: ':id/change-plan', element: <PlansChangePlan /> }
          ]
        },
        {
          path: 'messages',
          children: [{ path: '', element: <Messages /> }]
        },
        {
          path: 'store',
          element: <RoleBasedGuard accessibleRoles={['admin']} />,
          children: [
            { path: '', element: <Store /> },
            { path: 'pages', element: <StorePageList /> },
            { path: 'pages/create', element: <StorePage /> },
            { path: 'pages/:id', element: <StorePage /> },
            { path: 'links', element: <StoreLinkList /> },
            { path: 'links/create', element: <StoreLink /> },
            { path: 'links/:id', element: <StoreLink /> }
          ]
        },
        { path: 'support', element: <Support /> }
      ]
    },
    {
      path: 'admin/settings',
      element: (
        <AuthGuard>
          <SubscriptionGuard>
            <AdminSettingsLayout />
          </SubscriptionGuard>
        </AuthGuard>
      ),
      children: [
        { path: '', element: <Navigate to="/admin/settings/general" replace /> },
        { path: 'general', element: <SettingGeneral /> },
        { path: 'shipping', element: <SettingShipping /> },
        { path: 'domains', element: <SettingDomains /> },
        { path: 'files', element: <SettingFilesList /> },
        { path: 'notifications', element: <SettingNotification /> },
        { path: 'users', element: <SettingUsersList /> },
        { path: 'payments', element: <SettingPaymentOption /> },
        { path: 'messaging', element: <SettingMessaging /> },
        { path: 'plan', element: <SettingPlan /> },
        { path: 'dropshipping', element: <SettingDropshipping /> },
        { path: 'dropshipping/ae/complete', element: <SettingDropshippingAliExpressRedirect /> }
        // { path: 'billing', element: <SettingBilling /> },
        // { path: 'billing/statements', element: <SettingBillingStatements /> }
      ]
    },
    {
      path: 'admin/store',
      element: (
        <AuthGuard>
          <SubscriptionGuard>
            <AdminStoreEditorLayout />
          </SubscriptionGuard>
        </AuthGuard>
      ),
      children: [
        {
          path: 'manage',
          element: <RoleBasedGuard accessibleRoles={['admin']} />,
          children: [{ path: '', element: <StoreEditor /> }]
        }
      ]
    },

    // Main Routes
    {
      path: 'resources',
      children: [
        {
          path: 'start-your-online-ecommerce-store',
          element: <PPCEcommerceOnlineStore />
        }
      ]
    },
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'maintenance', element: <Maintenance /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { path: '', element: <LandingPage /> },
        { path: 'pricing', element: <Pricing /> },
        { path: 'contact-us', element: <ContactUs /> },
        { path: 'contact-us/sales', element: <ContactUsSalesMessage /> },
        { path: 'contact-us/general', element: <ContactUsGeneralMessage /> },
        { path: 'faqs', element: <Faqs /> },
        { path: 'payment-gateways', element: <PaymentGateways /> },
        { path: 'privacy-policy', element: <Privacy /> },
        { path: 'terms-and-conditions', element: <TermsAndConditions /> },
        { path: 'ecommerce/overview', element: <EcommerceOverview /> },
        { path: 'ecommerce/currencies', element: <EcommerceExchangeRates /> },
        { path: 'service-status', element: <ServiceStatus /> },
        {
          path: 'help',
          children: [
            {
              path: 'stripe',
              children: [{ path: 'how-to-connect-your-stripe-account', element: <HelpStripeSetupAccount /> }]
            },
            {
              path: 'klaviyo',
              children: [{ path: 'how-to-connect-your-klaviyo-account', element: <HelpKlaviyoSetupAccount /> }]
            },
            {
              path: 'ali-express',
              children: [{ path: 'how-to-import-a-product-from-ali-express', element: <HelpAliExpressProductId /> }]
            }
          ]
        },

        // Generic Pages
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

// IMPORT COMPONENTS

// Admin
const UserAccount = Loadable(lazy(() => import('../pages/admin_v2/UserAccount')));
const OrderList = Loadable(lazy(() => import('../pages/admin_v2/OrderList')));
const Order = Loadable(lazy(() => import('../pages/admin_v2/Order')));
const CustomerList = Loadable(lazy(() => import('../pages/admin_v2/CustomerList')));
const Customer = Loadable(lazy(() => import('../pages/admin_v2/Customer')));
const DiscountList = Loadable(lazy(() => import('../pages/admin_v2/DiscountList')));
const Discount = Loadable(lazy(() => import('../pages/admin_v2/Discount')));
const Product = Loadable(lazy(() => import('../pages/admin_v2/Product')));
const ProductList = Loadable(lazy(() => import('../pages/admin_v2/ProductList')));
const ProductsImport = Loadable(lazy(() => import('../pages/admin_v2/ProductsImport')));
const InventoryList = Loadable(lazy(() => import('../pages/admin_v2/InventoryList')));
const ProductGroupList = Loadable(lazy(() => import('../pages/admin_v2/ProductGroupList')));
const ProductGroup = Loadable(lazy(() => import('../pages/admin_v2/ProductGroup')));
const SettingGeneral = Loadable(lazy(() => import('../pages/admin_v2/SettingGeneral')));
const SettingShipping = Loadable(lazy(() => import('../pages/admin_v2/SettingShipping')));
const SettingDomains = Loadable(lazy(() => import('../pages/admin_v2/SettingDomains')));
const SettingFilesList = Loadable(lazy(() => import('../pages/admin_v2/SettingFilesList')));
const SettingNotification = Loadable(lazy(() => import('../pages/admin_v2/SettingNotification')));
const SettingUsersList = Loadable(lazy(() => import('../pages/admin_v2/SettingUsersList')));
const SettingPaymentOption = Loadable(lazy(() => import('../pages/admin_v2/SettingPaymentOption')));
const SettingMessaging = Loadable(lazy(() => import('../pages/admin_v2/SettingMessaging')));
const SettingPlan = Loadable(lazy(() => import('../pages/admin_v2/SettingPlan')));
const SettingDropshipping = Loadable(lazy(() => import('../pages/admin_v2/SettingDropshipping')));
const SettingDropshippingAliExpressRedirect = Loadable(
  lazy(() => import('../pages/admin_v2/SettingDropshippingAliExpressRedirect'))
);
const ReportOverview = Loadable(lazy(() => import('../pages/admin_v2/ReportOverview')));
const ReportList = Loadable(lazy(() => import('../pages/admin_v2/ReportList')));
const Report = Loadable(lazy(() => import('../pages/admin_v2/Report')));
const PlansChoosePlan = Loadable(lazy(() => import('../pages/admin_v2/PlansChoosePlan')));
const PlansPayment = Loadable(lazy(() => import('../pages/admin_v2/PlansPayment')));
const PlansChangePlan = Loadable(lazy(() => import('../pages/admin_v2/PlansChangePlan')));
const Messages = Loadable(lazy(() => import('../pages/admin_v2/Messages')));
const Store = Loadable(lazy(() => import('../pages/admin_v2/Store')));
const StoreEditor = Loadable(lazy(() => import('../pages/admin_v2/editor')));
const StorePageList = Loadable(lazy(() => import('../pages/admin_v2/StorePageList')));
const StorePage = Loadable(lazy(() => import('../pages/admin_v2/StorePage')));
const StoreLinkList = Loadable(lazy(() => import('../pages/admin_v2/StoreLinkList')));
const StoreLink = Loadable(lazy(() => import('../pages/admin_v2/StoreLink')));
const Support = Loadable(lazy(() => import('../pages/admin_v2/Support')));

// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const Register = Loadable(lazy(() => import('../pages/authentication/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/authentication/ResetPassword')));

// Main
const LandingPage = Loadable(lazy(() => import('../pages/LandingPage')));
const ContactUs = Loadable(lazy(() => import('../pages/ContactUs')));
const ContactUsSalesMessage = Loadable(lazy(() => import('../pages/ContactUsSalesMessage')));
const ContactUsGeneralMessage = Loadable(lazy(() => import('../pages/ContactUsGeneralMessage')));
const Faqs = Loadable(lazy(() => import('../pages/Faqs')));
const PaymentGateways = Loadable(lazy(() => import('../pages/PaymentGateways')));
const EcommerceOverview = Loadable(lazy(() => import('../pages/EcommerceOverview')));
const EcommerceExchangeRates = Loadable(lazy(() => import('../pages/EcommerceExchangeRates')));
const Privacy = Loadable(lazy(() => import('../pages/Privacy')));
const TermsAndConditions = Loadable(lazy(() => import('../pages/TermsAndConditions')));
const ServiceStatus = Loadable(lazy(() => import('../pages/ServiceStatus')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Pricing = Loadable(lazy(() => import('../pages/Pricing')));
const HelpStripeSetupAccount = Loadable(lazy(() => import('../pages/help/HelpStripeSetupAccount')));
const HelpKlaviyoSetupAccount = Loadable(lazy(() => import('../pages/help/HelpKlaviyoSetupAccount')));
const HelpAliExpressProductId = Loadable(lazy(() => import('../pages/help/HelpAliExpressProductId')));
const Page404 = Loadable(lazy(() => import('../pages/Page404')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));

// Blog
const PPCEcommerceOnlineStore = Loadable(lazy(() => import('../pages/ppc/PPCEcommerceOnlineStore')));
