import { Link as RouterLink } from 'react-router-dom';
import { Icon } from '@iconify/react';
import flashCircle from '@iconify/icons-mdi/flash-circle';
import { styled } from '@material-ui/core/styles';
import useMobile from 'src/hooks/useMobile';
import Page from 'src/components/Page';
import { Container, Stack, Typography, Box, Button, Card } from '@material-ui/core';
import { PATH_DASHBOARD } from 'src/routes/paths';
import Label from 'src/components/Label';

// ----------------------------------------------------------------------
const RootStyle = styled('div')(({ theme }) => ({
  height: '100%',
  display: 'flex',
  textAlign: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(8, 2)
}));

export const ReportsAdvancedCapabilityLockScreen = () => {
  const isMobile = useMobile();
  return (
    <Page title="Reports">
      <Container maxWidth="lg">
        <Card
          sx={{
            border: (theme) => `solid 1px ${theme.palette.divider}`,
            boxShadow: 0,
            borderRadius: 2,
            p: 5,
            margin: '0 auto',
            backgroundColor: 'secondary.main'
          }}
        >
          <Stack
            direction={isMobile ? 'column-reverse' : 'row'}
            spacing={isMobile ? 0 : 10}
            sx={{ width: '100%' }}
            justifyContent="center"
          >
            <Stack
              direction="column"
              spacing={6}
              sx={{ p: 2, maxWidth: 400 }}
              justifyContent="center"
              textAlign="center"
              alignSelf="center"
            >
              <Stack spacing={3}>
                <Stack spacing={1}>
                  <Box>
                    <Label variant="outlined" color="warning">
                      Advanced Plan
                    </Label>
                  </Box>
                  <Typography variant="h4" sx={{ color: 'common.white' }}>
                    Upgrade and get access to Advanced Reporting
                  </Typography>
                </Stack>
                <Typography variant="body1" sx={{ color: 'common.white', fontWeight: 300 }}>
                  Access a wide variety of reports and analyze your store's performance.
                </Typography>
              </Stack>
              <Button
                fullWidth
                variant="contained"
                size="large"
                startIcon={<Icon icon={flashCircle} />}
                LinkComponent={RouterLink}
                to={PATH_DASHBOARD.plans.root}
              >
                Upgrade Plan
              </Button>
            </Stack>

            <RootStyle>
              <Box
                component="img"
                alt="empty content"
                src="/static/illustrations/illustration_chart.svg"
                sx={{ height: 200 }}
              />
            </RootStyle>
          </Stack>
        </Card>
      </Container>
    </Page>
  );
};
