import { googleAnalyticsConfig } from '../config';

const setup = (...args) => {
  // TODO - commented for testing
  if (process.env.NODE_ENV !== 'production') {
    return;
  }
  if (!window.gtag) {
    return;
  }
  window.gtag(...args);
};

const track = {
  pageview: (props) => {
    setup('config', googleAnalyticsConfig, props);
  },
  event: (type, props) => {
    setup('event', type, props);
  },
  conversion: (uniqueId) => {
    setup('event', 'conversion', {
      send_to: 'AW-16656154369/wq1ACI7in8YZEIH-ooY-',
      value: 1.0,
      currency: 'GBP',
      transaction_id: uniqueId
    });
  }
};

export default track;
