import { styled } from '@material-ui/styles';
import { Card } from '@material-ui/core';

const SupportCard = styled(Card)(({ theme }) => ({
  '&&': {
    boxShadow: 'none',
    padding: theme.spacing(2),
    backgroundColor: 'unset'
  }
}));

export default SupportCard;
