import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  loading: {
    configuredPaymentsLoading: false,
    paymentOptionsLoading: false
  },
  error: {
    configuredPaymentsError: null,
    paymentOptionsError: null
  },
  configuredPayments: [],
  paymentOptions: []
};

const slice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    // START LOADING
    startPaymentConfiguredDataLoading(state) {
      state.loading.configuredPaymentsLoading = true;
    },

    startPaymentOptionsLoading(state) {
      state.loading.paymentOptionsLoading = true;
    },

    // HAS ERROR
    hasPaymentConfiguredDataError(state, action) {
      state.loading.configuredPaymentsLoading = false;
      state.error.configuredPaymentsError = action.payload;
    },
    hasPaymentOptionsError(state, action) {
      state.loading.paymentOptionsLoading = false;
      state.error.paymentOptionsError = action.payload;
    },

    // GET SHIPPING
    getPaymentConfiguredDataSuccess(state, action) {
      state.loading.configuredPaymentsLoading = false;
      state.error.configuredPaymentsError = null;
      state.configuredPayments = action.payload;
    },
    getPaymentOptionsSuccess(state, action) {
      state.loading.paymentOptionsLoading = false;
      state.error.paymentOptionsError = null;
      state.paymentOptions = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getConfiguredPaymentMethods() {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startPaymentConfiguredDataLoading());
      const response = await axios.get('/manage/payments');
      dispatch(slice.actions.getPaymentConfiguredDataSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasPaymentConfiguredDataError(error));
    }
  };
}

export function getPaymentOptions() {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startPaymentOptionsLoading());
      const response = await axios.get('/manage/options/payments');
      dispatch(slice.actions.getPaymentOptionsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasPaymentOptionsError(error));
    }
  };
}
