const initial = {
  pagableData: {
    hasNext: false,
    page: 0,
    total: 0,
    data: [],
    filters: []
  }
};

export default initial;
