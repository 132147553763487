import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import initial from './initial';

const INITIAL_PARAMS = {
  page: 0,
  rowsPerPage: 10,
  search: ''
};

const initialState = {
  isLoading: false,
  error: false,
  messages: initial.pagableData,
  queryParams: INITIAL_PARAMS
};

const slice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET MESSAGES
    getMessagesSuccess(state, action) {
      const messages = action.payload;

      state.isLoading = false;
      state.error = initialState.error;
      state.messages = messages;
    },
    updateQueryParams(state, action) {
      const newParams = action.payload;
      state.queryParams = { ...state.queryParams, ...newParams };
    },
    // REST
    resetMessages(state) {
      state.error = initialState.error;
      state.isLoading = initialState.isLoading;
      state.messages = initialState.messages;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { updateQueryParams, resetMessages } = slice.actions;

// ----------------------------------------------------------------------

export function getMessages(queryParams) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const { page, rowsPerPage, search } = queryParams;
      const response = await axios.get('/manage/messages', {
        params: {
          page,
          size: rowsPerPage,
          search
        }
      });
      dispatch(slice.actions.getMessagesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function sendReadMessageById(id) {
  return async () => {
    try {
      await axios.put(`/manage/messages/${id}/read`);
    } catch (error) {
      console.error(error);
    }
  };
}

export function deleteMessageById(id, queryParams) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.delete(`/manage/messages/${id}`);
      dispatch(getMessages(queryParams));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
