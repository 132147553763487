import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { styled, useTheme } from '@material-ui/core/styles';
import useCollapseDrawer from 'src/hooks/useCollapseDrawer';
import RoleBasedComponent from 'src/guards/RoleBasedComponent';
import AdminStoreEditorNavbar from './AdminStoreEditorNavbar';
import AdminStoreSidebar from './AdminStoreSidebar';
import { APPBAR_DESKTOP_MD, APPBAR_MOBILE, COLLAPSE_WIDTH } from './constants';

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APPBAR_MOBILE + 20,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APPBAR_DESKTOP_MD + 6
  }
}));

// ----------------------------------------------------------------------

export default function AdminStoreEditorLayout() {
  const theme = useTheme();
  const { collapseClick } = useCollapseDrawer();
  const [open, setOpen] = useState(false);

  return (
    <RootStyle>
      <RoleBasedComponent>
        <AdminStoreEditorNavbar onOpenSidebar={() => setOpen(true)} />
        <AdminStoreSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
      </RoleBasedComponent>
      <MainStyle
        sx={{
          transition: theme.transitions.create('margin', {
            duration: theme.transitions.duration.complex
          }),
          ...(collapseClick && {
            ml: `${COLLAPSE_WIDTH}px`
          })
        }}
      >
        <Outlet />
      </MainStyle>
    </RootStyle>
  );
}
