import { defaultTo } from 'lodash';
import { styled } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Link, Typography } from '@material-ui/core';
import { sentenceCase } from 'change-case';
import { PATH_DASHBOARD } from '../../routes/paths';
import MyAvatar from '../../components/MyAvatar';

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  margin: theme.spacing(0, 2.5),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[500_12],
  height: 76
}));

export const AdminSidebarUserCard = ({ isCollapse, user, tenant }) => {
  return (
    <Box>
      {isCollapse ? (
        <MyAvatar sx={{ width: '35px', height: '35px', mx: 'auto', mt: 2 }} />
      ) : (
        <Link underline="none" component={RouterLink} to={PATH_DASHBOARD.account.root}>
          <AccountStyle>
            <MyAvatar />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                ml: 2,
                height: 76
              }}
            >
              <Typography
                variant="subtitle2"
                noWrap
                textOverflow="ellipsis"
                sx={{ width: '150px', color: 'secondary.contrastText' }}
              >
                {tenant?.storeName}
              </Typography>
              <Typography variant="body2" sx={{ width: '100%', color: 'secondary.contrastText' }}>
                {sentenceCase(defaultTo(user?.role, ''))}
              </Typography>
            </Box>
          </AccountStyle>
        </Link>
      )}
    </Box>
  );
};
