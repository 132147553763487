import currency from 'currency.js';
import { replace } from 'lodash';
import numeral from 'numeral';

// ----------------------------------------------------------------------

export function fCurrency(number) {
  return numeral(number).format(Number.isInteger(number) ? '$0,0' : '$0,0.00');
}

export function fNumberCurrency(number, currency) {
  return `${currency}${numeral(number).format('0,0.00')}`;
}

export function fNumberCurrencyShort(number, currency) {
  return `${currency}${numeral(number).format('0,0')}`;
}

export function fNumberCurrencyCode(number, currencyCode) {
  return `${currencyCode} ${numeral(number).format('0,0.00')}`;
}

export function fStringCurrencyCode(value, currencyCode) {
  return `${currencyCode} ${value}`;
}

export function fNumberPercentage(number) {
  return `${numeral(number).format('0,0.00')}%`;
}

export function fPercent(number) {
  return numeral(number / 100).format('0.0%');
}

export function fNumber(number) {
  return numeral(number).format();
}

export function fShortenNumber(number) {
  return replace(numeral(number).format('0.00a'), '.00', '');
}

export function fData(number) {
  return numeral(number).format('0.0 b');
}

export function fCurrencyFromMinorUnits(number, symbol) {
  return currency(number, {
    symbol,
    fromCents: true,
    precision: 2
  }).format();
}
