import { Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import useMobile from 'src/hooks/useMobile';

export default function ConfirmDeleteDialog({ title, body, text, open, handleCancel, handleDelete }) {
  const isMobile = useMobile();
  return (
    <Dialog open={open} onClose={handleCancel}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent sx={{ ...(!isMobile && { width: 500 }) }}>
        {text ? (
          <Typography variant="body2" color="text.secondary" sx={{ pt: 2 }}>
            {text}
          </Typography>
        ) : (
          body
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button variant="contained" onClick={handleDelete} autoFocus>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}
