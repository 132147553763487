import { Box, Button, Stack, Typography, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';
import { Icon } from '@iconify/react';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
import flashCircle from '@iconify/icons-mdi/flash-circle';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { ADVANCED } from 'src/utils/capabilityConstants';
import { CapabilityPlanLabel } from '../../CapabilityPlanLabel';

const Text = ({ color, children }) => (
  <Typography variant="body2" sx={{ color: color || 'text.secondary' }}>
    {children}
  </Typography>
);

const AccordionStyle = styled(Accordion)(({ theme }) => ({
  '&.MuiAccordion-root': {
    border: `solid 1px ${theme.palette.divider}`,
    '&:before': {
      backgroundColor: 'unset'
    },
    boxShadow: 'none'
  }
}));

export const CapabilityShippingRate = () => {
  return (
    <AccordionStyle sx={{ boxShadow: 'none' }}>
      <AccordionSummary expandIcon={<Icon icon={arrowIosDownwardFill} width={20} height={20} />}>
        <Stack direction="row" spacing={1} justifyContent="space-between" sx={{ width: '100%', pr: 2 }}>
          <Typography variant="subtitle2" sx={{ color: 'primary.main' }}>
            Tiered Shipping Rates
          </Typography>
          <CapabilityPlanLabel tier={ADVANCED} />
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <Stack direction="column" sx={{ width: '100%' }} spacing={1}>
          <Text>Control when this shipping rate will appear based on your customers shopping cart total.</Text>
          <Box sx={{ pt: 1.5 }}>
            <Button
              variant="outlined"
              size="medium"
              startIcon={<Icon icon={flashCircle} />}
              LinkComponent={RouterLink}
              to={PATH_DASHBOARD.plans.root}
            >
              Upgrade Plan
            </Button>
          </Box>
        </Stack>
      </AccordionDetails>
    </AccordionStyle>
  );
};
