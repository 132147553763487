// material
import { useCallback, useState } from 'react';
import { defaultTo, filter } from 'lodash';
import useMobile from 'src/hooks/useMobile';
import { Switch, Typography, Stack, Box } from '@material-ui/core';
import { paramCase } from 'change-case';
import PricingPlanCard from './PricingPlanCard';
import { PaymentPlanSkeleton } from './PaymentPlanSkeleton';

// ----------------------------------------------------------------------

export default function PricingPlans({ isLargeView, isLoggedIn, loading, plans, currentPlan, handleAction }) {
  const [monthly, setMonthly] = useState(false);
  const isMobile = useMobile();

  const getPlans = useCallback(() => {
    if (monthly) {
      return filter(defaultTo(plans, []), (o) => paramCase(defaultTo(o.frequency, '')) === 'monthly');
    }
    return filter(defaultTo(plans, []), (o) => paramCase(defaultTo(o.frequency, '')) === 'yearly');
  }, [plans, monthly]);

  const data = getPlans();

  return loading ? (
    <Box>
      <PaymentPlanSkeleton />
    </Box>
  ) : (
    <>
      <Stack direction="row" alignItems="center" justifyContent="center">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ mr: 1.5, ...(monthly && { color: (theme) => theme.palette.primary.main }) }}
        >
          <strong>MONTHLY</strong>
        </Typography>
        <Switch defaultChecked={!monthly} onChange={() => setMonthly((prev) => !prev)} />
        <Box sx={{ display: 'flex' }}>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              mr: 1.5,
              ...(!monthly && { color: (theme) => theme.palette.primary.main }),
              alignSelf: 'center'
            }}
          >
            <strong>YEARLY</strong>
          </Typography>
          <Typography
            gutterBottom
            component="span"
            variant="overline"
            sx={{
              color: 'text.secondary',
              padding: 0,
              margin: 0,
              alignSelf: 'center'
            }}
          >
            (Save 25%)
          </Typography>
        </Box>
      </Stack>

      <Box>
        <Stack
          spacing={10}
          direction={isMobile ? 'column' : 'row'}
          justifyContent="center"
          sx={{ ...(isMobile && { alignItems: 'center' }) }}
        >
          {data.map((plan) => (
            <PricingPlanCard
              key={plan.id}
              isLargeView={isLargeView}
              isLoggedIn={isLoggedIn}
              plan={plan}
              currentPlan={currentPlan}
              handleAction={handleAction}
            />
          ))}
        </Stack>
      </Box>
    </>
  );
}
