import { Box, Card, Stack, Typography } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { Icon } from '@iconify/react';
import exclamationCircleFill from '@iconify/icons-bi/exclamation-circle-fill';

const RootBox = styled(Box)(() => ({
  '&&': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));

const RootCard = styled(Card)(({ theme }) => ({
  '&&': {
    boxShadow: 'none',
    padding: theme.spacing(5, 5, 10),
    marginTop: theme.spacing(5),
    textAlign: 'center'
  }
}));

const AlertIcon = styled(Icon)(({ theme }) => ({
  '&&': {
    color: theme.palette.warning.main
  }
}));

const GenericComponentError = ({
  title = 'Opps, Something went wrong',
  description = 'Looks like we cannot load this data at this moment',
  sx
}) => (
  <RootBox sx={{ ...sx }}>
    <RootCard>
      <Stack sx={{ textAlign: 'center', alignItems: 'center' }} direction="column" spacing={2}>
        <AlertIcon icon={exclamationCircleFill} width={100} />
        <Typography variant="h4">{title}</Typography>
        <Typography variant="body2" color="text.secondary" sx={{ fontSize: 16 }}>
          {description}
        </Typography>
      </Stack>
    </RootCard>
  </RootBox>
);

export default GenericComponentError;
