import { useCallback, useMemo, useState } from 'react';
import { Icon } from '@iconify/react';
import { useSnackbar } from 'notistack5';
import {
  Card,
  Typography,
  Grid,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  CardHeader,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent
} from '@material-ui/core';
import {
  deleteShippingRate,
  deleteShippingZone,
  updateShippingZone,
  updateShippingRate,
  createShippingRate
} from 'src/redux/slices/shipping';
import { isEmpty } from 'lodash';
import { LoadingButton } from '@material-ui/lab';
import plusFill from '@iconify/icons-eva/plus-fill';
import SortableListHead from 'src/components/_admin_v2/SortableListHead';
import { fNumberCurrency } from 'src/utils/formatNumber';
import useMobile from 'src/hooks/useMobile';
import ListMoreMenu from '../ListMoreMenu';
import { SkeletonLoadingSmall } from '../SkeletonLoadingSmall';
import ShippingZoneCountries from './ShippingZoneCountries';
import ShippingRateDialog from './ShippingRateDialog';
import ConfirmDeleteDialog from './ConfirmDeleteDialog';
import SupportCard from './SupportCard';

const TABLE_HEAD = [
  { id: 'rateName', label: 'Rate name', alignRight: false },
  { id: 'enabled', label: 'Status', alignRight: false },
  { id: 'price', label: 'Price', alignRight: false },
  { id: 'condition', label: 'Tiered Rate', alignRight: false },
  { id: '' }
];

const SupportCardData = ({ data, onEdit, onDelete }) => {
  const { id, name, countries } = data;
  const numOfCountries = countries.length;
  return (
    <>
      <CardHeader
        title={
          <>
            <Typography variant="h6" sx={{ mb: 0 }}>
              {name}
            </Typography>
            <Typography variant="body2">
              {`Shipping zone covers ${numOfCountries} ${numOfCountries > 1 ? 'countries' : 'country'}`}
            </Typography>
          </>
        }
        action={<ListMoreMenu onEdit={onEdit} onDelete={() => onDelete(id)} />}
        sx={{ p: 0 }}
      />
    </>
  );
};

const ShippingRateRow = ({ rate: data, index, currency, onEdit, onDelete }) => {
  const { symbol } = currency;
  const { id, enabled, name, rate, enableCondition } = data;
  return (
    <TableRow
      key={index}
      tabIndex={-1}
      sx={{
        height: '68px'
      }}
    >
      <TableCell align="left" sx={{ maxWidth: '30px' }}>
        <Typography noWrap variant="inherit">
          {name}
        </Typography>
      </TableCell>
      <TableCell align="left">{enabled ? 'Enabled' : 'Disabled'}</TableCell>
      <TableCell align="left">{fNumberCurrency(rate, symbol)}</TableCell>
      <TableCell align="left">{enableCondition ? 'Applied' : 'Not Applied'}</TableCell>
      <TableCell align="left" sx={{ maxWidth: '10px' }}>
        <ListMoreMenu onDelete={() => onDelete(id)} onEdit={() => onEdit(id)} />
      </TableCell>
    </TableRow>
  );
};

const ConfirmShippingZoneDeleteDialog = ({ zoneName, ...rest }) => (
  <ConfirmDeleteDialog
    title="Are you sure?"
    text={
      <>
        Please confirm you want to delete shipping zone "<strong>{zoneName}</strong>"
      </>
    }
    {...rest}
  />
);

const ConfirmShippingRateDeleteDialog = ({ rate, handleClose, handleDelete, ...rest }) => {
  const { id, name = '' } = rate || {};
  return (
    <ConfirmDeleteDialog
      title="Are you sure?"
      text={
        <>
          Please confirm you want to delete shipping rate <strong>"{name}</strong>"
        </>
      }
      handleClose={handleClose}
      handleDelete={() => handleDelete(id)}
      {...rest}
    />
  );
};

const ShippingZoneDialog = ({ open, onClose, body }) => (
  <Dialog fullWidth open={open} onClose={onClose} scroll="body">
    <DialogTitle sx={{ pb: 2 }}>Update Shipping Zone</DialogTitle>
    <DialogContent dividers>{body}</DialogContent>
  </Dialog>
);

const UpdateShippingRateDialog = ({ ...props }) => <ShippingRateDialog {...props} />;
const CreateShippingRateDialog = ({ ...props }) => <ShippingRateDialog {...props} />;

export default function ShippingZoneCard({
  isAuthorized,
  countries,
  currency,
  loading,
  data,
  setDeleting,
  handleGetShippingZones
}) {
  const { enqueueSnackbar } = useSnackbar();
  const isMobile = useMobile();
  const [open, setOpen] = useState(false);
  const [openCreateRate, setOpenCreateRate] = useState(false);
  const [openRate, setOpenRate] = useState({
    open: false,
    rate: null
  });
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [openConfirmRateDelete, setOpenConfirmRateDelete] = useState({
    open: false,
    rate: null
  });
  const { id: zoneId, name: zoneName } = data || {};

  const handleClickOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);
  const handleCloseCreateRate = useCallback(() => {
    setOpenCreateRate(false);
  }, []);

  const handleCloseRate = useCallback(() => {
    setOpenRate({
      open: false,
      rate: null
    });
  }, []);

  const handleShippingZoneCancel = useCallback(() => {
    setOpenConfirmDelete(false);
  }, []);

  const handleShippingZoneDelete = useCallback(async () => {
    try {
      setDeleting(true);
      handleShippingZoneCancel();
      await deleteShippingZone(zoneId);
      enqueueSnackbar('Shipping zone deleted', { variant: 'success' });
      handleGetShippingZones();
      setDeleting(false);
    } catch (error) {
      enqueueSnackbar('Opps. Something went wrong', { variant: 'error' });
      handleShippingZoneCancel();
      setDeleting(false);
    }
  }, [enqueueSnackbar, zoneId, setDeleting, handleShippingZoneCancel, handleGetShippingZones]);

  const handleShippingRateCancel = useCallback(() => {
    setOpenConfirmRateDelete({ open: false, rate: null });
  }, []);

  const handleShippingRateDelete = useCallback(
    async (rateId) => {
      try {
        setDeleting(true);
        handleShippingRateCancel();
        await deleteShippingRate(zoneId, rateId);
        enqueueSnackbar('Shipping rate deleted', { variant: 'success' });
        handleGetShippingZones();
        setDeleting(false);
      } catch (error) {
        enqueueSnackbar('Opps. Something went wrong', { variant: 'error' });
        handleShippingRateCancel();
        setDeleting(false);
      }
    },
    [enqueueSnackbar, zoneId, handleShippingRateCancel, handleGetShippingZones, setDeleting]
  );

  const handleUpdate = useCallback(
    async (values) => {
      await updateShippingZone(values);
      handleClose();
      enqueueSnackbar('Shipping zone updated', { variant: 'success' });
      handleGetShippingZones();
    },
    [enqueueSnackbar, handleClose, handleGetShippingZones]
  );

  const handleUpdateError = useCallback(() => {
    handleClose();
    enqueueSnackbar('Opps. Something went wrong.', { variant: 'error' });
  }, [enqueueSnackbar, handleClose]);

  const handleUpdateRate = useCallback(
    async (values) => {
      await updateShippingRate(zoneId, values);
      handleCloseRate();
      enqueueSnackbar('Shipping rate updated', { variant: 'success' });
      handleGetShippingZones();
    },
    [enqueueSnackbar, zoneId, handleCloseRate, handleGetShippingZones]
  );

  const handleUpdateRateError = useCallback(() => {
    handleCloseRate();
    enqueueSnackbar('Opps. Something went wrong.', { variant: 'error' });
  }, [enqueueSnackbar, handleCloseRate]);

  const handleCreateRate = useCallback(
    async (values) => {
      await createShippingRate(zoneId, values);
      handleCloseCreateRate();
      enqueueSnackbar('Shipping rate created', { variant: 'success' });
      handleGetShippingZones();
    },
    [enqueueSnackbar, zoneId, handleCloseCreateRate, handleGetShippingZones]
  );

  const handleCreateRateError = useCallback(() => {
    handleCloseCreateRate();
    enqueueSnackbar('Opps. Something went wrong.', { variant: 'error' });
  }, [enqueueSnackbar, handleCloseCreateRate]);

  const ShippingZoneCountriesMemo = useMemo(
    () => (
      <ShippingZoneCountries
        countries={countries}
        shippingZone={data}
        handleSave={handleUpdate}
        handleSaveError={handleUpdateError}
        onClose={handleClose}
      />
    ),
    [countries, data, handleUpdate, handleUpdateError, handleClose]
  );

  const UpdateShippingRateDialogMemo = useMemo(
    () =>
      openRate.rate && (
        <UpdateShippingRateDialog
          isAuthorized={isAuthorized}
          currency={currency}
          shippingRate={openRate.rate}
          open={openRate.open}
          onClose={handleCloseRate}
          handleSave={handleUpdateRate}
          handleSaveError={handleUpdateRateError}
        />
      ),
    [currency, isAuthorized, openRate, handleCloseRate, handleUpdateRate, handleUpdateRateError]
  );

  const CreateShippingRateDialogMemo = useMemo(
    () =>
      openCreateRate && (
        <CreateShippingRateDialog
          isAuthorized={isAuthorized}
          currency={currency}
          open={openCreateRate}
          onClose={handleCloseCreateRate}
          handleSave={handleCreateRate}
          handleSaveError={handleCreateRateError}
        />
      ),
    [currency, isAuthorized, openCreateRate, handleCloseCreateRate, handleCreateRate, handleCreateRateError]
  );

  const ConfirmShippingZoneDeleteDialogMemo = useMemo(
    () => (
      <ConfirmShippingZoneDeleteDialog
        zoneName={zoneName}
        zoneId={zoneId}
        open={openConfirmDelete}
        handleCancel={handleShippingZoneCancel}
        handleDelete={handleShippingZoneDelete}
      />
    ),
    [zoneName, zoneId, openConfirmDelete, handleShippingZoneCancel, handleShippingZoneDelete]
  );

  const ConfirmShippingRateDeleteDialogMemo = useMemo(
    () => (
      <ConfirmShippingRateDeleteDialog
        rate={openConfirmRateDelete.rate}
        open={openConfirmRateDelete.open}
        handleCancel={handleShippingRateCancel}
        handleDelete={handleShippingRateDelete}
      />
    ),
    [openConfirmRateDelete, handleShippingRateCancel, handleShippingRateDelete]
  );

  return (
    <>
      {!loading && <ShippingZoneDialog body={ShippingZoneCountriesMemo} open={open} onClose={handleClose} />}
      {!loading && openRate.rate && UpdateShippingRateDialogMemo}
      {!loading && CreateShippingRateDialogMemo}
      {!loading && ConfirmShippingZoneDeleteDialogMemo}
      {!loading && ConfirmShippingRateDeleteDialogMemo}

      <Grid container spacing={isMobile ? 0 : 3}>
        <Grid item xs={12} md={4}>
          <SupportCard>
            {loading ? (
              <SkeletonLoadingSmall sx={{ pt: 5, pr: 5 }} />
            ) : (
              <SupportCardData data={data} onEdit={handleClickOpen} onDelete={() => setOpenConfirmDelete(true)} />
            )}
          </SupportCard>
        </Grid>

        <Grid item xs={12} md={8}>
          <Card
            sx={{
              p: 3
            }}
          >
            {loading ? (
              <SkeletonLoadingSmall />
            ) : (
              <>
                {isEmpty(data.shippingRates) ? (
                  <Stack spacing={1} sx={{ mt: 5, textAlign: 'center' }}>
                    <Typography variant="h6" color="primary">
                      No Shipping Rates
                    </Typography>
                    <Typography variant="body2">Get started by adding a shipping rate to this zone</Typography>
                  </Stack>
                ) : (
                  <TableContainer>
                    <Table>
                      <SortableListHead
                        headLabel={TABLE_HEAD}
                        rowCount={data.shippingRates.length}
                        showCheckbox={false}
                        enableSorting={false}
                      />
                      <TableBody>
                        {data.shippingRates.map((_rate, index) => (
                          <ShippingRateRow
                            key={index}
                            rate={_rate}
                            index={index}
                            currency={currency}
                            onEdit={() =>
                              setOpenRate({
                                open: true,
                                rate: _rate
                              })
                            }
                            onDelete={() =>
                              setOpenConfirmRateDelete({
                                open: true,
                                rate: _rate
                              })
                            }
                          />
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
                <LoadingButton
                  size="medium"
                  variant="outlined"
                  startIcon={<Icon icon={plusFill} />}
                  sx={{ float: 'right', mt: 4, mr: 3 }}
                  onClick={() => setOpenCreateRate(true)}
                >
                  Add Shipping Rate
                </LoadingButton>
              </>
            )}
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
