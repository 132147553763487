import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  loading: {
    dataLoading: false,
    countriesLoading: false,
    deleteShippingZoneLoading: false
  },
  error: {
    dataError: null,
    countriesError: null
  },
  data: [],
  countries: []
};

const slice = createSlice({
  name: 'shipping',
  initialState,
  reducers: {
    // START LOADING
    startDataLoading(state) {
      state.loading.dataLoading = true;
    },
    startCountriesLoading(state) {
      state.loading.countriesLoading = true;
    },

    // HAS ERROR
    hasDataError(state, action) {
      state.loading.dataLoading = false;
      state.error.dataError = action.payload;
    },
    hasCountriesError(state, action) {
      state.loading.countriesLoading = false;
      state.error.countriesError = action.payload;
    },
    hasDeleteError(state, action) {
      state.error.deleteError = action.payload;
    },

    // GET SHIPPING
    getShippingSuccess(state, action) {
      state.loading.dataLoading = false;
      state.data = action.payload.map((shippingZone) => {
        return {
          ...shippingZone,
          countries: shippingZone.countries.map((country) => country.id)
        };
      });
    },
    getShippingCountriesSuccess(state, action) {
      state.loading.countriesLoading = false;
      state.countries = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getShippingZones(page, size) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startDataLoading());
      const response = await axios.get('/manage/shipping', {
        params: {
          page,
          size
        }
      });
      dispatch(slice.actions.getShippingSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasDataError(error));
    }
  };
}

export function getShippingZoneCountries() {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startCountriesLoading());
      const response = await axios.get('/manage/shipping/countries');
      dispatch(slice.actions.getShippingCountriesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasCountriesError(error));
    }
  };
}

export async function deleteShippingZone(id) {
  await axios.delete(`/manage/shipping/${id}`);
}

export async function deleteShippingRate(zoneId, rateId) {
  await axios.delete(`/manage/shipping/${zoneId}/rates/${rateId}`);
}

export async function createShippingZone(values) {
  const { name, countries } = values;
  return axios.post(`/manage/shipping`, {
    name,
    countries
  });
}

export async function updateShippingZone(values) {
  const { id, name, countries } = values;
  return axios.put(`/manage/shipping/${id}`, {
    name,
    countries
  });
}

export async function updateShippingRate(shippingZoneId, values) {
  const { id, name, description, enabled, rate, enableCondition, minAmount, maxAmount } = values;
  return axios.put(`/manage/shipping/${shippingZoneId}/rates/${id}`, {
    name,
    description,
    enabled,
    rate,
    enableCondition,
    minAmount,
    maxAmount
  });
}

export async function createShippingRate(shippingZoneId, values) {
  const { name, description, enabled, rate, enableCondition, minAmount, maxAmount } = values;
  return axios.post(`/manage/shipping/${shippingZoneId}/rates`, {
    name,
    description,
    enabled,
    rate,
    enableCondition,
    minAmount,
    maxAmount
  });
}
