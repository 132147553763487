import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import initial from './initial';

// ----------------------------------------------------------------------

const initialState = {
  loading: {
    customersIsLoading: false,
    customerIsLoading: false
  },
  error: {
    customersError: null,
    customerError: null
  },
  customers: initial.pagableData,
  customer: null
};

const slice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    // START LOADING
    startCustomersLoading(state) {
      state.loading.customersIsLoading = true;
    },
    startCustomerLoading(state) {
      state.loading.customerIsLoading = true;
    },

    // HAS ERROR
    hasCustomersError(state, action) {
      state.loading.customersIsLoading = false;
      state.error.customersError = action.payload;
    },
    hasCustomerError(state, action) {
      state.loading.customerIsLoading = false;
      state.error.customerError = action.payload;
    },

    // GET PRODUCTS
    getCustomersSuccess(state, action) {
      state.loading.customersIsLoading = false;
      state.error.customersError = null;
      state.customers = action.payload;
    },
    getCustomerSuccess(state, action) {
      state.loading.customerIsLoading = false;
      state.error.customerError = null;
      state.customer = action.payload;
    },

    // RESET
    resetCustomers(state) {
      state.loading.customersIsLoading = false;
      state.customers = initial.pagableData;
    },
    resetCustomer(state) {
      state.loading.customerIsLoading = false;
      state.customer = null;
    }
  }
});

// Reducer
export default slice.reducer;

export const { resetCustomers, resetCustomer } = slice.actions;

// ----------------------------------------------------------------------

export function getCustomers(page, rowsPerPage, orderBy, order, filter) {
  return async (dispatch) => {
    dispatch(slice.actions.startCustomersLoading());
    try {
      const response = await axios.get('/manage/customers', {
        params: {
          page,
          size: rowsPerPage,
          orderBy,
          order,
          search: filter
        }
      });
      dispatch(slice.actions.getCustomersSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasCustomersError(error));
    }
  };
}

export function getCustomer(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startCustomerLoading());
    try {
      const response = await axios.get(`/manage/customers/${id}`);
      dispatch(slice.actions.getCustomerSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasCustomerError(error));
    }
  };
}

export function deleteCustomer(id) {
  return axios.delete(`/manage/customers/${id}`);
}

export function deleteCustomers(ids) {
  return axios.post(`/manage/customers`, null, {
    params: {
      ids: ids.toString()
    }
  });
}
