import { Stack, Typography, Box } from '@material-ui/core';
import { sentenceCase } from 'sentence-case';

const SupportCardData = ({ name, description, caption, button }) => {
  return (
    <Stack spacing={4}>
      <Box>
        {caption && (
          <Box sx={{ mb: 1 }}>
            <Typography variant="overline" color="text.secondary">
              {caption}
            </Typography>
          </Box>
        )}
        {name && (
          <Typography variant="h6" sx={{ mb: 1 }}>
            {name}
          </Typography>
        )}
        {description && (
          <Typography variant="body2" color="text.secondary">
            {sentenceCase(description)}
          </Typography>
        )}
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'end' }}>{button}</Box>
    </Stack>
  );
};

export default SupportCardData;
