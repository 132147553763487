import { styled } from '@material-ui/core/styles';
import LoadingScreenFazer from './LoadingScreenFazer';

const RootStyle = styled('div')(({ theme }) => ({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.background?.default
}));

export default function LoadingScreen({ ...other }) {
  return (
    <RootStyle {...other}>
      <LoadingScreenFazer />
    </RootStyle>
  );
}
