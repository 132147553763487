// ----------------------------------------------------------------------

const shape = {
  borderRadius: 8,
  borderRadiusSm: 12,
  borderRadiusMd: 16,
  borderRadiusCircle: '50%'
};

export default shape;
