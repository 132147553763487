import { Box, Button } from '@material-ui/core';

export const PaymentPlanActionButton = ({ isLoggedIn, isCurrentPlan, handleAction, sx, ...other }) => (
  <Box display="flex" justifyContent="center" sx={{ width: '100%' }}>
    <Box sx={{ width: '100%', ...sx }}>
      <Button
        fullWidth
        size="large"
        variant="contained"
        disabled={isLoggedIn && isCurrentPlan}
        onClick={handleAction}
        {...other}
      >
        {!isLoggedIn && 'Start Free Trial'}
        {isLoggedIn && isCurrentPlan && 'Current Plan'}
        {isLoggedIn && !isCurrentPlan && 'Choose Plan'}
      </Button>
    </Box>
  </Box>
);
