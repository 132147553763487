import { alpha, styled } from '@material-ui/core/styles';
import { Box, Stack, AppBar, Toolbar } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import AccountPopover from 'src/layouts/admin/AccountPopover';
import Logo from 'src/components/Logo';
import { PATH_PAGE } from 'src/routes/paths';

export const PLANS_NAVBAR_MOBILE = 64;
export const PLANS_NAVBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72)
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: PLANS_NAVBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: PLANS_NAVBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));

export default function PlansNavbar() {
  return (
    <RootStyle>
      <ToolbarStyle>
        <Box component={RouterLink} to={PATH_PAGE.home} sx={{ display: 'inline-flex' }}>
          <Logo
            logo={{
              variant: 'white',
              contrast: 'primary',
              background: 'primary'
            }}
            text={{
              variant: 'primary'
            }}
          />
        </Box>

        <Box sx={{ flexGrow: 1 }} />

        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          <AccountPopover />
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
