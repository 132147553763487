import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  loading: {
    tenantIsLoading: false,
    industriesLoading: false,
    countriesLoading: false,
    unitsLoading: false,
    weightLoading: false,
    currenciesLoading: false,
    timezonesLoading: false,
    localesLoading: false,
    configuredCurrenciesLoading: false
  },
  error: {
    error: null,
    tenantError: null
  },
  tenant: null,
  options: {
    industry: [],
    country: [],
    unit: [],
    weight: [],
    currency: [],
    configuredCurrencies: [],
    timezones: [],
    locales: []
  }
};

const slice = createSlice({
  name: 'tenant',
  initialState,
  reducers: {
    // START LOADING
    startTenantLoading(state) {
      state.loading.tenantIsLoading = true;
    },

    startIndustriesLoading(state) {
      state.loading.industriesLoading = true;
    },
    startCountriesLoading(state) {
      state.loading.countriesLoading = true;
    },
    startUnitsLoading(state) {
      state.loading.unitsLoading = true;
    },
    startWeightLoading(state) {
      state.loading.weightLoading = true;
    },
    startCurrencyLoading(state) {
      state.loading.currenciesLoading = true;
    },
    startTimezonesLoading(state) {
      state.loading.timezonesLoading = true;
    },
    startLocalesLoading(state) {
      state.loading.localesLoading = true;
    },
    startConfiguredCurrenciesLoading(state) {
      state.loading.configuredCurrenciesLoading = true;
    },

    // HAS ERROR
    hasTenantError(state, action) {
      state.loading.tenantIsLoading = false;
      state.error.tenantError = action.payload;
    },

    hasGenericError(state, action) {
      state.loading.industriesLoading = false;
      state.loading.countriesLoading = false;
      state.loading.unitsLoading = false;
      state.loading.weightLoading = false;
      state.loading.currenciesLoading = false;
      state.error.error = action.payload;
    },

    // GET TENANT
    getTenantSuccess(state, action) {
      state.loading.tenantIsLoading = false;
      state.error.tenantError = null;
      state.tenant = action.payload;
    },

    getIndustryOptionsSuccess(state, action) {
      state.loading.industriesLoading = false;
      state.options.industry = action.payload;
    },
    getCountriesOptionsSuccess(state, action) {
      state.loading.countriesLoading = false;
      state.options.country = action.payload;
    },
    getUnitOptionsSuccess(state, action) {
      state.loading.unitsLoading = false;
      state.options.unit = action.payload;
    },
    getWeightOptionsSuccess(state, action) {
      state.loading.weightLoading = false;
      state.options.weight = action.payload;
    },
    getCurrencyOptionsSuccess(state, action) {
      state.loading.currenciesLoading = false;
      state.options.currency = action.payload;
    },
    getTimezoneOptionsSuccess(state, action) {
      state.loading.timezonesLoading = false;
      state.options.timezones = action.payload;
    },
    getLocaleOptionsSuccess(state, action) {
      state.loading.localesLoading = false;
      state.options.locales = action.payload;
    },
    getConfiguredCurrencySuccess(state, action) {
      state.loading.configuredCurrenciesLoading = false;
      state.options.configuredCurrencies = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export async function getTenant(dispatch) {
  try {
    const response = await axios.get('/manage/tenant');
    const data = response.data;
    if (data?.id) {
      axios.defaults.headers.common['x-uuid'] = data?.id;
    }
    dispatch(slice.actions.getTenantSuccess(data));
  } catch (error) {
    console.error(error);
    dispatch(slice.actions.hasTenantError(error));
  }
}

export async function updateTenantGeneral(dispatch, values) {
  const response = await axios.put('/manage/tenant', {
    storeName: values.storeName,
    email: values.storeEmail,
    supportEmail: values.supportEmail,
    generalEmail: values.generalEmail,
    industry: values.storeIndustry,
    legalName: values.legalCompany,
    registrationNo: values.registrationNo,
    number: values.number,
    address: values.address,
    apartment: values.apartment,
    city: values.city,
    postCode: values.postcode,
    country: values.country,
    units: values.unitSystem,
    weight: values.weight,
    currency: values.currency,
    timezone: values.timezone,
    locale: values.locale,
    taxPercentage: values.taxPercentage
  });
  dispatch(slice.actions.getTenantSuccess(response.data));
}

export async function updateConfiguredCurrencies(currencies) {
  await axios.put('/manage/currency', { currencies });
}

// GET OPTIONS

export function getIndustryOptions() {
  return async (dispatch) => {
    dispatch(slice.actions.startIndustriesLoading());
    try {
      const response = await axios.get('/manage/tenant/options/industries');
      dispatch(slice.actions.getIndustryOptionsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasGenericError(error));
    }
  };
}

export function getCountryOptions() {
  return async (dispatch) => {
    dispatch(slice.actions.startCountriesLoading());
    try {
      const response = await axios.get('/manage/tenant/options/countries');
      dispatch(slice.actions.getCountriesOptionsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasGenericError(error));
    }
  };
}

export function getUnitOptions() {
  return async (dispatch) => {
    dispatch(slice.actions.startUnitsLoading());
    try {
      const response = await axios.get('/manage/tenant/options/units');
      dispatch(slice.actions.getUnitOptionsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasGenericError(error));
    }
  };
}

export function getWeightOptions() {
  return async (dispatch) => {
    dispatch(slice.actions.startWeightLoading());
    try {
      const response = await axios.get('/manage/tenant/options/weight');
      dispatch(slice.actions.getWeightOptionsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasGenericError(error));
    }
  };
}

export function getCurrencyOptions() {
  return async (dispatch) => {
    dispatch(slice.actions.startCurrencyLoading());
    try {
      const response = await axios.get('/manage/tenant/options/currencies');
      dispatch(slice.actions.getCurrencyOptionsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasGenericError(error));
    }
  };
}

export function getTimezoneOptions() {
  return async (dispatch) => {
    dispatch(slice.actions.startTimezonesLoading());
    try {
      const response = await axios.get('/manage/tenant/options/timezones');
      dispatch(slice.actions.getTimezoneOptionsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasGenericError(error));
    }
  };
}

export function getLocaleOptions() {
  return async (dispatch) => {
    dispatch(slice.actions.startLocalesLoading());
    try {
      const response = await axios.get('/manage/tenant/options/locales');
      dispatch(slice.actions.getLocaleOptionsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasGenericError(error));
    }
  };
}

export function getConfiguredCurrencies() {
  return async (dispatch) => {
    dispatch(slice.actions.startConfiguredCurrenciesLoading());
    try {
      const response = await axios.get('/manage/currency');
      dispatch(slice.actions.getConfiguredCurrencySuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasGenericError(error));
    }
  };
}
