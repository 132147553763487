import { createSlice } from '@reduxjs/toolkit';
import { merge } from 'lodash';
import moment from 'moment';
import { fDateISO } from 'src/utils/formatTime';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

// As there is no paging we need to avoid limiting the data.
const MAX_NUM_OF_ROWS = 10000;

const INITIAL_CHART_QUERY_STATE = {
  dateRange: [fDateISO(moment().subtract(7, 'day').toDate()), fDateISO(new Date())],
  page: 0,
  size: MAX_NUM_OF_ROWS
};

const INITAL_REPORTS_DATA = {
  columns: [],
  data: [],
  options: []
};

const INITAL_REPORT_DATA = {
  enableDateRange: false,
  dateRange: [null, null],
  totals: null,
  pagination: {
    sort: [],
    page: 0,
    size: 1,
    total: 0
  },
  columns: [],
  data: []
};

const initialState = {
  loading: {
    reportLoading: false,
    reportsLoading: false,
    charts: {
      salesOverTime: false,
      salesByProduct: false,
      sessionsOverTime: false,
      sessionsByBrowser: false,
      sessionsByDevice: false,
      sessionsByLocation: false
    }
  },
  error: {
    reportError: null,
    reportsError: null,
    charts: {
      salesOverTime: null,
      salesByProduct: null,
      sessionsOverTime: null,
      sessionsByBrowser: null,
      sessionsByDevice: null,
      sessionsByLocation: null
    }
  },
  report: INITAL_REPORT_DATA,
  reports: INITAL_REPORTS_DATA,
  chartsQuery: INITIAL_CHART_QUERY_STATE,
  charts: {
    salesOverTime: null,
    salesByProduct: null,
    sessionsOverTime: null,
    sessionsByBrowser: null,
    sessionsByDevice: null,
    sessionsByLocation: null
  }
};

const slice = createSlice({
  name: 'report',
  initialState,
  reducers: {
    // START LOADING
    startReportLoading(state) {
      state.loading.reportLoading = true;
    },
    startReportsLoading(state) {
      state.loading.reportsLoading = true;
    },
    startReportChartLoading(state, action) {
      const { key } = action.payload;
      state.loading.charts[key] = true;
    },

    // HAS ERROR
    hasReportError(state, action) {
      state.loading.reportLoading = false;
      state.error.reportError = action.payload;
    },
    hasReportsError(state, action) {
      state.loading.reportsLoading = false;
      state.error.reportsError = action.payload;
    },
    hasReportChartError(state, action) {
      const { key, error } = action.payload;
      state.loading.charts[key] = false;
      state.error.charts[key] = error;
    },

    // GET REPORT
    getReportSuccess(state, action) {
      state.loading.reportLoading = false;
      state.error.reportError = null;
      state.report = action.payload;
    },
    getReportsSuccess(state, action) {
      state.loading.reportsLoading = false;
      state.error.reportsError = null;
      state.reports = action.payload;
    },
    getReportChartSuccess(state, action) {
      const { key, data } = action.payload;
      state.loading.charts[key] = false;
      state.error.charts[key] = null;
      state.charts[key] = data;
    },
    resetReport(state) {
      state.loading.reportLoading = false;
      state.report = INITAL_REPORT_DATA;
      state.error.reportError = null;
    },
    updateChartsQuery(state, action) {
      state.chartsQuery = merge(state.chartsQuery, { dateRange: action.payload });
    }
  }
});

// Reducer
export default slice.reducer;

export const { resetReport, updateChartsQuery } = slice.actions;

// ----------------------------------------------------------------------

export function getReportByResource(resource, query) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startReportLoading());
      const response = await axios.post(`/manage/reports/${resource}`, query);
      dispatch(slice.actions.getReportSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasReportError(error));
    }
  };
}

export function getChartByResource(chart, resource, query) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startReportChartLoading({ key: chart }));
      const response = await axios.post(`/manage/reports/${resource}`, query);
      dispatch(slice.actions.getReportChartSuccess({ key: chart, data: response.data }));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasReportChartError({ key: chart, error }));
    }
  };
}

export function getReports() {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startReportsLoading());
      const response = await axios.get('/manage/reports');
      dispatch(slice.actions.getReportsSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasReportsError(error));
    }
  };
}
