import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  loading: {
    configuredMessagingLoading: false,
    messagingOptionsLoading: false
  },
  error: {
    configuredMessagingError: null,
    messagingOptionsError: null
  },
  configuredMessaging: [],
  messagingOptions: []
};

const slice = createSlice({
  name: 'messaging',
  initialState,
  reducers: {
    // START LOADING
    startMessagingConfiguredDataLoading(state) {
      state.loading.configuredMessagingLoading = true;
    },

    startMessagingOptionsLoading(state) {
      state.loading.messagingOptionsLoading = true;
    },

    // HAS ERROR
    hasMessagingConfiguredDataError(state, action) {
      state.loading.configuredMessagingLoading = false;
      state.error.configuredMessagingError = action.payload;
    },
    hasMessagingOptionsError(state, action) {
      state.loading.messagingOptionsLoading = false;
      state.error.messagingOptionsError = action.payload;
    },

    // GET MESSAGING
    getMessagingConfiguredDataSuccess(state, action) {
      state.loading.configuredMessagingLoading = false;
      state.error.configuredMessagingError = null;
      state.configuredMessaging = action.payload;
    },
    getMessagingOptionsSuccess(state, action) {
      state.loading.messagingOptionsLoading = false;
      state.error.messagingOptionsError = null;
      state.messagingOptions = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getConfiguredMessagingServices() {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startMessagingConfiguredDataLoading());
      const response = await axios.get('/manage/events');
      dispatch(slice.actions.getMessagingConfiguredDataSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasMessagingConfiguredDataError(error));
    }
  };
}

export function getMessagingOptions() {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startMessagingOptionsLoading());
      const response = await axios.get('/manage/options/events');
      dispatch(slice.actions.getMessagingOptionsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasMessagingOptionsError(error));
    }
  };
}
