import React, { useState } from 'react';
import { Alert, Stack } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { stripeConfig } from 'src/config';
import { createSubscription } from 'src/services/payments';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { LoadingButton } from '@material-ui/lab';

const SUCCESS_STATUS = 'success';
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(stripeConfig.publicKey);

export const StripePaymentComponent = ({ currency, ...others }) => {
  const theme = useTheme();

  const options = {
    mode: 'subscription',
    amount: 0,
    currency,
    // Fully customizable with appearance API.
    appearance: {
      labels: 'floating',
      variables: {
        fontWeightNormal: 400,
        fontWeightMedium: 600,
        fontWeightBold: 700,
        colorPrimary: theme.palette.primary.main,
        colorBackground: theme.palette.background.paper,
        colorText: theme.palette.text.secondary,
        colorDanger: theme.palette.error.main,
        spacingUnit: theme.spacing(1),
        borderRadius: `${theme.shape.textfieldBorderRadius}px`
      },
      rules: {
        '.Label--floating': {
          color: 'var(--colorPrimary)'
        },
        '.Input': {
          padding: theme.spacing(1, 2),
          boxShadow: 'none'
        },
        '.Input:hover': {
          boxShadow: 'none'
        },
        '.Input:focus': {
          border: '2px solid var(--colorPrimary)',
          boxShadow: 'none'
        },
        '.Input:disabled': {
          backgroundColor: theme.palette.action.disabledBackground,
          boxShadow: 'none'
        },
        '.Tab': {
          padding: theme.spacing(2.5, 2.5),
          boxShadow: 'none'
        },
        '.Tab:disabled': {
          backgroundColor: theme.palette.action.disabledBackground,
          boxShadow: 'none'
        },
        '.Tab:focus': {
          boxShadow: 'none'
        },
        '.Tab--selected:focus': {
          boxShadow: 'none'
        }
      }
    }
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      <PaymentComponentForm {...others} />
    </Elements>
  );
};

const INIT_STATE = {
  elementReady: false,
  errorMessage: null,
  loading: false
};

const PaymentComponentForm = ({ priceId }) => {
  const elements = useElements();
  const stripe = useStripe();
  const [state, setState] = useState(INIT_STATE);
  const isNotReady = !stripe || !elements || !state.elementReady;

  const handleError = (error) => {
    setState((prev) => {
      return { ...prev, errorMessage: error, loading: false };
    });
  };

  const handleSubmitPayment = async (event) => {
    event.preventDefault();
    if (!stripe) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setState((prev) => {
      return {
        ...prev,
        errorMessage: null,
        loading: true
      };
    });

    // Trigger form validation and wallet collection
    try {
      const { error: submitError } = await elements.submit();
      if (submitError) {
        handleError(submitError.message);
        return;
      }

      // Create the subscription
      const res = await createSubscription(priceId);
      const { type, clientSecret } = res;
      const confirmIntent = type === 'setup' ? stripe.confirmSetup : stripe.confirmPayment;

      // Confirm the Intent using the details collected by the Payment Element
      const { error } = await confirmIntent({
        elements,
        clientSecret,
        confirmParams: {
          return_url: PATH_DASHBOARD.plans.paymentRedirectUrl(SUCCESS_STATUS)
        }
      });

      if (error) {
        // This point is only reached if there's an immediate error when confirming the Intent.
        // Show the error to your customer (for example, "payment details incomplete").
        handleError(error.message);
      } else {
        // Your customer is redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer is redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.
      }
    } catch (e) {
      handleError('Something went wrong');
    }
  };

  return (
    <Stack spacing={2} sx={{ p: 2 }}>
      <form onSubmit={handleSubmitPayment}>
        <Stack spacing={2}>
          {state.errorMessage && <Alert severity="error">{state.errorMessage}</Alert>}

          <PaymentElement
            onReady={() =>
              setState((prev) => {
                return { ...prev, elementReady: true };
              })
            }
          />
          <LoadingButton
            loading={state.loading || isNotReady}
            variant="contained"
            fullWidth
            size="large"
            type="submit"
            disabled={isNotReady}
          >
            Complete Subscription
          </LoadingButton>
        </Stack>
      </form>
    </Stack>
  );
};
