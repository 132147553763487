// routes
import { PATH_DASHBOARD, PATH_PAGE } from '../../routes/paths';

// ----------------------------------------------------------------------

const getMenuConfig = (isAuthenticated) => {
  return [
    {
      title: 'Pricing',
      path: isAuthenticated ? PATH_DASHBOARD.plans.root : PATH_PAGE.pricing
    },
    {
      title: 'Get Started',
      path: PATH_PAGE.info.ecommerce.overview
    },
    {
      title: 'Support',
      children: [
        {
          subheader: 'Resources',
          items: [
            { title: 'Payment Gateways', path: PATH_PAGE.general.paymentGateways },
            { title: `FAQ's`, path: PATH_PAGE.faqs }
          ]
        }
      ]
    }
  ];
};

export default getMenuConfig;
