import { Box, Stack, Typography } from '@material-ui/core';
import { defaultTo } from 'lodash';

const InfoBlock = ({ spacing = 0.5, caption, title, description, body, buttons, actions, titleObj, colour }) => (
  <Stack spacing={spacing} direction="column">
    <Stack direction="row" spacing={1} justifyContent="space-between">
      <Box>
        <Typography variant="overline" sx={{ color: defaultTo(colour, 'text.secondary') }}>
          {caption}
        </Typography>
        {title && (
          <Typography variant="h6" sx={{ color: (theme) => defaultTo(colour, theme.palette.primary.main) }}>
            {title}
          </Typography>
        )}
        {titleObj && titleObj}
        {description && (
          <Typography variant="body2" sx={{ color: defaultTo(colour, 'text.secondary') }}>
            {description}
          </Typography>
        )}
      </Box>
      {actions}
    </Stack>

    {body}
    {buttons}
  </Stack>
);

export default InfoBlock;
