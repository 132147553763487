import { useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { styled } from '@material-ui/core/styles';
import { Box, Card, Stack, Container, Typography, Button } from '@material-ui/core';
import InfoBlock from 'src/components/_admin_v2/InfoBlock';
import Page from '../../components/Page';
import PlansNavbar, { PLANS_NAVBAR_DESKTOP, PLANS_NAVBAR_MOBILE } from './onboarding/PlansNavbar';
import { PlansChoosePlanView } from './PlansChoosePlan';
import { PAYMENT_INTENT_SETUP_KEY, PAYMENT_STATUS_KEY, PlansPaymentView } from './PlansPayment';

// ----------------------------------------------------------------------

const CHOOSE_PLAN_STEPS = [
  {
    key: 0,
    caption: 'Choose plan',
    label: 'Start Your Subscription',
    description: 'Explore all the features we have to offer and choose the plan that works best for you'
  },
  {
    key: 1,
    caption: 'Payment',
    label: 'Complete Payment',
    description: 'Last step before you start your subscription and begin setting up your store'
  }
];

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: PLANS_NAVBAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: PLANS_NAVBAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

const HeaderCardStyle = styled(Card)(({ theme }) => ({
  // maxWidth: 1000,
  width: '100%',
  margin: '0 auto',
  border: `solid 1px ${theme.palette.divider}`,
  boxShadow: 0,
  borderRadius: theme.shape.borderRadiusSm,
  padding: theme.spacing(5),
  backgroundColor: theme.palette.secondary.main
}));

const Step = styled(Box)(({ theme }) => ({
  width: '100%',
  height: 3,
  display: 'flex',
  backgroundColor: theme.palette.divider
}));

export default function PlansGetStarted() {
  const { search } = useLocation();
  const [state, setState] = useState({
    activeStep: 0,
    planId: null
  });

  // STATUS APPENDED PARAM
  const queryParams = new URLSearchParams(search);
  const status = queryParams.get(PAYMENT_STATUS_KEY);
  const piSetup = queryParams.get(PAYMENT_INTENT_SETUP_KEY);
  const piSetupSecret = queryParams.get(PAYMENT_INTENT_SETUP_KEY);
  const isComplete = status === 'success' && Boolean(piSetup) && Boolean(piSetupSecret);

  // STEPS
  const isActivePaymentStep = Boolean(state.activeStep === 1 && state.planId) || isComplete;

  const handleCreatePlan = useCallback((id) => setState({ activeStep: 1, planId: id }), [setState]);

  const handleChangePlan = useCallback(() => {
    // Not supported here
  }, []);

  const HeaderCard = () => {
    const activePage = CHOOSE_PLAN_STEPS[state.activeStep];
    return (
      <HeaderCardStyle>
        <Stack spacing={4}>
          <Stack spacing={1} direction="row" justifyContent="space-evenly">
            {CHOOSE_PLAN_STEPS.slice(0, 3).map(({ key }) => (
              <Step
                key={key}
                sx={{ ...(key <= state.activeStep && { backgroundColor: (theme) => theme.palette.common.white }) }}
              />
            ))}
          </Stack>

          <InfoBlock
            colour="white"
            caption={activePage.caption}
            titleObj={
              <Typography color="primary" variant="h6">
                {activePage.label}
              </Typography>
            }
            description={activePage.description}
            {...(state.activeStep > 0 && {
              buttons: (
                <Box sx={{ pt: 2 }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      setState((prev) => {
                        return {
                          ...prev,
                          activeStep: 0
                        };
                      })
                    }
                  >
                    {state.activeStep === 1 && 'Back To Plans'}
                  </Button>
                </Box>
              )
            })}
          />
        </Stack>
      </HeaderCardStyle>
    );
  };

  return (
    <RootStyle>
      <Page title="Choose your plan | Shoppy Monkey" sx={{ width: '100%' }}>
        <PlansNavbar />
        <MainStyle>
          <Container maxWidth="lg">
            <Stack direction="column" spacing={6}>
              <Box>
                <HeaderCard />
              </Box>

              {!isComplete && state.activeStep === 0 && (
                <PlansChoosePlanView onCreatePlan={handleCreatePlan} onChangePlan={handleChangePlan} />
              )}
              {isActivePaymentStep && <PlansPaymentView planId={state.planId} isComplete={isComplete} />}
            </Stack>
          </Container>
        </MainStyle>
      </Page>
    </RootStyle>
  );
}
