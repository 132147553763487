import { Link as RouterLink } from 'react-router-dom';
import { alpha, styled } from '@material-ui/core/styles';
import { Box, Stack, Link, Container, Typography } from '@material-ui/core';
import Logo from 'src/components/Logo';
import { PATH_AUTH, PATH_PAGE } from '../../routes/paths';
import Page from '../../components/Page';
import { LoginForm } from '../../components/authentication/login';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  },
  backgroundImage: `linear-gradient(to left, #8a2387, #9c21f2, #e94057)`
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 530,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(8, 0)
}));

const InnerContainer = styled(Stack)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.background.paper, 0.9),
  padding: theme.spacing(3, 4, 6),
  borderRadius: theme.shape.borderRadiusSm
}));

// ----------------------------------------------------------------------

export default function Login() {
  const Header = () => {
    return (
      <Stack spacing={2} direction="column">
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="h4" gutterBottom color="secondary">
            Login
          </Typography>
          <Typography variant="body1" sx={{ color: 'text.secondary' }}>
            Access your account
          </Typography>
        </Box>
      </Stack>
    );
  };

  const LogoStyle = () => (
    <Box display="flex" sx={{ pt: 2, position: 'absolute' }}>
      <Link component={RouterLink} to={PATH_PAGE.home} underline="none">
        <Logo
          logo={{
            variant: 'white',
            contrast: 'primary',
            collapsedHeight: 25
          }}
          text={{
            variant: 'white'
          }}
        />
      </Link>
    </Box>
  );

  return (
    <RootStyle title="Login | Shoppy Monkey">
      <Container maxWidth="xl">
        <LogoStyle />

        <ContentStyle>
          <InnerContainer spacing={3} direction="column">
            <Header />

            <LoginForm />

            <Typography variant="body2" align="center" sx={{ mt: 3 }}>
              Don’t have an account?&nbsp;
              <Link variant="subtitle2" component={RouterLink} to={PATH_AUTH.signup}>
                Get started
              </Link>
            </Typography>
          </InnerContainer>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
