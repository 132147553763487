import { Button, Dialog, DialogTitle, DialogActions, Stack, Alert, Typography } from '@material-ui/core';
import { Icon } from '@iconify/react';
import baselineRocket from '@iconify/icons-ic/baseline-rocket';
import { LoadingButton } from '@material-ui/lab';
import { useState } from 'react';

// ----------------------------------------------------------------------

export default function DeployStoreDialoag({ open, loading = false, handleClose, handleDeployAndExit, handleDeploy }) {
  const [clicked, setClicked] = useState(null);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Deploy Store</DialogTitle>
      <Stack spacing={3} sx={{ p: (theme) => theme.spacing(3) }}>
        <Alert severity="info">Changes can take up to 5 mins to appear on your store.</Alert>
        <Typography variant="body2" color="text.secondary">
          You can monitor the status of this deployment on your store manager. For any technical enquires you can reach
          out to our support team
        </Typography>
      </Stack>
      <DialogActions>
        <Button disabled={loading} onClick={handleClose}>
          Cancel
        </Button>
        <LoadingButton
          disabled={loading}
          loading={clicked === 0 && loading}
          variant="text"
          onClick={() => {
            setClicked(0);
            handleDeployAndExit();
          }}
          autoFocus
        >
          Deploy & Exit
        </LoadingButton>
        <LoadingButton
          endIcon={<Icon icon={baselineRocket} />}
          disabled={loading}
          loading={clicked === 1 && loading}
          variant="contained"
          onClick={() => {
            setClicked(1);
            handleDeploy();
          }}
          autoFocus
        >
          Deploy
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
