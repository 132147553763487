import { Icon } from '@iconify/react';
import { Box, IconButton, Stack, Tooltip, Typography, useMediaQuery } from '@material-ui/core';
import { useTheme, styled } from '@material-ui/core/styles';
import mobileDevice from '@iconify/icons-akar-icons/mobile-device';
import desktopDevice from '@iconify/icons-akar-icons/desktop-device';
import useEditor from 'src/hooks/useEditor';
import { DESKTOP_KEY, MOBILE_KEY } from 'src/contexts/EditorContext';

const SelectedLine = styled(Box)(({ theme }) => ({
  height: '5px',
  width: '5px',
  margin: '0 auto',

  borderRadius: theme.shape.borderRadiusCircle,
  backgroundColor: theme.palette.primary.main
}));

export function ResizeWindowButtons() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const position = isDesktop ? 'left' : 'center';
  const { previewKey: selected, onMobileView, onDesktopView } = useEditor();

  return (
    <Stack spacing={1}>
      <Typography variant="overline" sx={{ textAlign: position, color: (theme) => theme.palette.text.secondary }}>
        Preview Size
      </Typography>
      <Stack direction="row" spacing={2} justifyContent={position}>
        <div>
          <Tooltip title="Mobile">
            <IconButton
              onClick={() => {
                onMobileView(MOBILE_KEY);
              }}
              color="primary"
            >
              <Icon icon={mobileDevice} width={20} height={20} />
            </IconButton>
          </Tooltip>
          {selected === MOBILE_KEY && <SelectedLine />}
        </div>

        <div>
          <Tooltip title="Desktop">
            <IconButton
              onClick={() => {
                onDesktopView(DESKTOP_KEY);
              }}
              color="primary"
            >
              <Icon icon={desktopDevice} width={20} height={20} />
            </IconButton>
          </Tooltip>
          {selected === DESKTOP_KEY && <SelectedLine />}
        </div>
      </Stack>
    </Stack>
  );
}
