import { Icon } from '@iconify/react';
import checkmarkFill from '@iconify/icons-eva/checkmark-fill';
// material
import { alpha, styled } from '@material-ui/core/styles';
import { Card, Typography, Box, Stack } from '@material-ui/core';
import { fCurrencyFromMinorUnits } from 'src/utils/formatNumber';
// routes
import Label from 'src/components/Label';
import { PaymentPlanActionButton } from './PaymentPlanActionButton';

// ----------------------------------------------------------------------

export const PricingPlanRootStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  minHeight: '100%',
  display: 'flex',
  position: 'relative',
  alignItems: 'center',
  flexDirection: 'column',
  padding: theme.spacing(3),
  [theme.breakpoints.up(414)]: {
    padding: theme.spacing(5)
  }
}));

export default function PricingPlanCard({ isLargeView = true, currentPlan, plan, isLoggedIn, handleAction }) {
  const { id, title, subtitle, isPopular, priceMonthly, currency, features } = plan;
  const { symbol } = currency;
  const isCurrentPlan = currentPlan && currentPlan === id;

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',
        height: 670,
        width: '100%',
        maxWidth: 480
      }}
    >
      {isPopular && (
        <Box
          sx={{
            position: 'absolute',
            top: -14,
            zIndex: 1000,
            backgroundColor: (theme) => theme.palette.background.paper
          }}
        >
          <Label color="success" sx={{ padding: 1.5 }}>
            MOST POPULAR
          </Label>
        </Box>
      )}
      <PricingPlanRootStyle
        sx={{ ...(isPopular && { border: (theme) => `2px solid ${alpha(theme.palette.success.main, 0.5)}` }) }}
      >
        <Stack spacing={1} alignItems="center">
          <Typography variant={isLargeView ? 'h3' : 'h4'} sx={{ color: 'text.primary' }}>
            {title}
          </Typography>

          <Typography variant="body2" sx={{ textAlign: 'center', color: 'text.secondary' }}>
            {subtitle}
          </Typography>
        </Stack>

        <Box sx={{ display: 'flex', justifyContent: 'flex-end', my: 2 }}>
          <Typography variant={isLargeView ? 'h2' : 'h3'}>{fCurrencyFromMinorUnits(priceMonthly, symbol)}</Typography>
          <Typography
            gutterBottom
            component="span"
            variant="subtitle2"
            sx={{
              alignSelf: 'flex-end',
              color: 'text.secondary'
            }}
          >
            /mo
          </Typography>
        </Box>

        <Stack component="ul" spacing={2} sx={{ my: 5, width: 1 }}>
          <Typography
            variant="overline"
            sx={{
              color: 'text.secondary'
            }}
          >
            Highlights
          </Typography>
          {features.map((item) => (
            <Stack
              key={item}
              component="li"
              direction="row"
              alignItems="center"
              spacing={1.5}
              sx={{ typography: 'body2', color: 'text.primary' }}
            >
              <Box
                component={Icon}
                icon={checkmarkFill}
                sx={{ width: 20, height: 20, color: (theme) => theme.palette.success.main }}
              />
              <Typography variant="body2">{item}</Typography>
            </Stack>
          ))}
        </Stack>

        <Box flexGrow={1} />
        <PaymentPlanActionButton
          isLoggedIn={isLoggedIn}
          isCurrentPlan={isCurrentPlan}
          handleAction={() => handleAction(id)}
        />
      </PricingPlanRootStyle>
    </Box>
  );
}
