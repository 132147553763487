import { useTheme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { FazerAnimation } from 'react-fazer-animation';

// ----------------------------------------------------------------------

export default function LoadingScreenFazer() {
  const theme = useTheme();
  const PRIMARY_MAIN = theme.palette.primary.main;

  return (
    <Box sx={{ position: 'relative', height: '150px' }}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <FazerAnimation bodyColor={PRIMARY_MAIN} longfazersColor="transparent" />
      </div>
    </Box>
  );
}
