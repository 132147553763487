import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  loading: {
    ordersIsLoading: false,
    orderIsLoading: false,
    orderSummaryIsLoading: false
  },
  error: {
    ordersError: null,
    orderError: null,
    orderSummaryError: null
  },
  orders: {
    hasNext: false,
    page: 0,
    total: 0,
    data: []
  },
  order: null,
  orderSummary: null
};

const slice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    // START LOADING
    startOrdersLoading(state) {
      state.loading.ordersIsLoading = true;
    },
    startOrderLoading(state) {
      state.loading.orderIsLoading = true;
    },
    startOrderSummaryLoading(state) {
      state.loading.orderSummaryIsLoading = true;
    },

    // HAS ERROR
    hasOrdersError(state, action) {
      state.loading.ordersIsLoading = false;
      state.error.ordersError = action.payload;
    },
    hasOrderError(state, action) {
      state.loading.orderIsLoading = false;
      state.error.orderError = action.payload;
    },
    hasOrderSummaryError(state, action) {
      state.loading.orderSummaryIsLoading = false;
      state.error.orderSummaryError = action.payload;
    },

    // GET ORDERS
    getOrdersSuccess(state, action) {
      state.loading.ordersIsLoading = false;
      state.error.ordersError = null;
      state.orders = action.payload;
    },
    getOrderSuccess(state, action) {
      state.loading.orderIsLoading = false;
      state.error.orderError = null;
      state.order = action.payload;
    },
    getOrderSummarySuccess(state, action) {
      state.loading.orderSummaryIsLoading = false;
      state.error.orderSummaryError = null;
      state.orderSummary = action.payload;
    },

    // RESET
    resetOrderSummary(state) {
      state.loading.orderSummaryIsLoading = false;
      state.orderSummary = null;
    }
  }
});

// Reducer
export default slice.reducer;

export const { resetOrderSummary } = slice.actions;

// ----------------------------------------------------------------------

export function getOrders(page, rowsPerPage, orderBy, order, filterId, status) {
  return async (dispatch) => {
    dispatch(slice.actions.startOrdersLoading());
    try {
      const response = await axios.get('/manage/orders', {
        params: {
          page,
          size: rowsPerPage,
          orderBy,
          order,
          search: filterId,
          status
        }
      });
      dispatch(slice.actions.getOrdersSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasOrdersError(error));
    }
  };
}

export function getOrderById(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startOrderLoading());
    try {
      const response = await axios.get(`/manage/orders/${id}`);
      dispatch(slice.actions.getOrderSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasOrderError(error));
    }
  };
}

export function getOrderSummaryById(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startOrderSummaryLoading());
    try {
      const response = await axios.get(`/manage/orders/${id}/summary`);
      dispatch(slice.actions.getOrderSummarySuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasOrderSummaryError(error));
    }
  };
}

export function cancelOrderById(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startOrderLoading());
    try {
      const response = await axios.put(`/manage/orders/${id}/cancel`);
      dispatch(slice.actions.getOrderSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasOrderError(error));
    }
  };
}

export const updateOrderFulfillment = (id, values) =>
  axios.put(`/manage/orders/${id}/fulfillment`, {
    ...values
  });
