import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

const getStatus = (active) => (active ? 'ACTIVE' : 'INACTIVE');

// ----------------------------------------------------------------------

const initialState = {
  loading: {
    usersLoading: false,
    userRolesLoading: false
  },
  error: {
    usersError: null,
    userRolesError: null
  },
  roles: [],
  users: {
    hasNext: false,
    page: 0,
    total: 0,
    data: []
  }
};

const slice = createSlice({
  name: 'tenantUser',
  initialState,
  reducers: {
    // START LOADING
    startUsersLoading(state) {
      state.loading.usersLoading = true;
    },
    startUserRolesLoading(state) {
      state.loading.userRolesLoading = true;
    },

    // HAS ERROR
    hasUsersError(state, action) {
      state.loading.usersLoading = false;
      state.error.usersError = action.payload;
    },
    hasUserRolesError(state, action) {
      state.loading.usersLoading = false;
      state.error.userRolesError = action.payload;
    },

    // GET PRODUCTS
    getUsersSuccess(state, action) {
      state.loading.usersLoading = false;
      state.error.usersError = null;
      state.users = action.payload;
    },
    getUserRolesSuccess(state, action) {
      state.loading.userRolesLoading = false;
      state.error.userRolesError = null;
      state.roles = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getUserRoles() {
  return async (dispatch) => {
    dispatch(slice.actions.startUserRolesLoading());
    try {
      const response = await axios.get('/manage/tenant/options/roles');
      dispatch(slice.actions.getUserRolesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasUserRolesError(error));
    }
  };
}

export function getUsers(page, rowsPerPage, orderBy, order, filterName) {
  return async (dispatch) => {
    dispatch(slice.actions.startUsersLoading());
    try {
      const response = await axios.get('/manage/users', {
        params: {
          page,
          size: rowsPerPage,
          orderBy,
          order,
          search: filterName
        }
      });
      dispatch(slice.actions.getUsersSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasUsersError(error));
    }
  };
}

export function updateUser(values, roles) {
  const { id, active, firstName, lastName, username, password } = values;
  return axios.put(`/manage/users/${id}`, {
    status: getStatus(active),
    firstName,
    lastName,
    username,
    password,
    roles
  });
}

export function createUser(values, roles) {
  const { active, firstName, lastName, username, password } = values;
  return axios.post(`/manage/users/`, {
    status: getStatus(active),
    firstName,
    lastName,
    username,
    password,
    roles
  });
}

export function deleteUser(id) {
  return axios.delete(`/manage/users/${id}`);
}

export function deleteUsers(ids) {
  return axios.post(`/manage/users/batch`, null, {
    params: {
      ids: ids.toString()
    }
  });
}
