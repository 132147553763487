import { Box, Stack } from '@material-ui/core';
import { defaultTo } from 'lodash';
import LogoSingle from './LogoSingle';
import LogoSingleText from './LogoSingleText';

export default function Logo({ isLogo = false, sx, spacing, logo, text }) {
  const defaultLogoHeight = defaultTo(logo?.height, 27);
  const defaultCollapsedLogoHeight = defaultTo(logo?.collapsedHeight, 27);
  const defaultHeight = defaultTo(text?.height, 35);

  return (
    <Box sx={{ display: 'flex', justifyContent: 'start' }}>
      <Stack direction="row" spacing={defaultTo(spacing, 0.3)} alignItems="center" sx={{ ...sx }}>
        <LogoSingle {...logo} sx={{ height: isLogo ? defaultCollapsedLogoHeight : defaultLogoHeight, ...logo.sx }} />
        {!isLogo && <LogoSingleText {...text} sx={{ height: defaultHeight, width: '100%', ...text.sx }} />}
      </Stack>
    </Box>
  );
}
