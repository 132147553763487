import { styled } from '@material-ui/core/styles';
import { Drawer } from '@material-ui/core';
import { MHidden } from '../../components/@material-extend';
import { COLLAPSE_WIDTH, DRAWER_WIDTH } from '../admin-store/constants';

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.complex
    })
  }
}));

export default function AdminGenericSidebar({
  isOpenSidebar,
  onCloseSidebar,
  isCollapse,
  collapseClick,
  collapseHover,
  onHoverEnter,
  onHoverLeave,
  children
}) {
  return (
    <RootStyle
      sx={{
        width: {
          lg: isCollapse ? COLLAPSE_WIDTH : DRAWER_WIDTH
        },
        ...(collapseClick && {
          position: 'absolute'
        })
      }}
    >
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              backgroundColor: (theme) => theme.palette.secondary.darker,
              color: (theme) => theme.palette.secondary.contrastText
            }
          }}
        >
          {children}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              backgroundColor: (theme) => theme.palette.secondary.darker,
              color: (theme) => theme.palette.secondary.contrastText,
              transition: (theme) =>
                theme.transitions.create('width', {
                  easing: theme.transitions.easing.easeInOut,
                  duration: theme.transitions.duration.shorter
                }),
              ...(isCollapse && {
                width: COLLAPSE_WIDTH
              }),
              ...(collapseHover && {
                borderRight: 0,
                backdropFilter: 'blur(6px)',
                WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
                boxShadow: (theme) => theme.customShadows.z20,
                color: (theme) => theme.palette.secondary.contrastText
              })
            }
          }}
        >
          {children}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}
