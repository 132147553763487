// PAGES
export const REPORTS_LIST_PAGE = 'reports/list';
export const CUSTOMERS_DETAIL_PAGE = 'admin/customers/:id';
export const MESSAGES_PAGE = 'messages';
export const USERS_PAGE = 'settings/users';

// CAPABILITY GROUP - Used to group a set of capabilities
export const REPORTS_CAPABILITY_GROUP = 'reports-group';
export const SHIPPING_CAPABILITY_GROUP = 'shipping-group';
export const SHIPPING_RATES_CAPABILITY_GROUP = 'shipping-rates-group';
export const USERS_CAPABILITY_GROUP = 'users-group';
export const CURRENCY_CAPABILITY_GROUP = 'currency-group';
export const CUSTOMERS_CAPABILITY_GROUP = 'customers-group';
export const MESSAGES_CAPABILITY_GROUP = 'messages-group';

// LIMITS
export const USERS_ADVANCED_LIMIT = 1000;
export const USERS_BASIC_LIMIT = 1;

export const SHIPPING_ZONES_ADVANCED_LIMIT = 100;
export const SHIPPING_ZONES_BASIC_LIMIT = 1;

// REPORTS
export const REPORTS_ADVANCED_CAPABILITY = {
  key: 'reports-advanced',
  group: REPORTS_CAPABILITY_GROUP
};
export const REPORTS_BASIC_CAPABILITY = {
  key: 'reports-basic',
  group: REPORTS_CAPABILITY_GROUP
};

// USERS
export const USERS_ADVANCED_CAPABILITY = {
  key: 'users-advanced',
  group: USERS_CAPABILITY_GROUP,
  limit: USERS_ADVANCED_LIMIT
};
export const USERS_BASIC_CAPABILITY = {
  key: 'users-basic',
  group: USERS_CAPABILITY_GROUP,
  limit: USERS_BASIC_LIMIT
};

// SHIPPING
export const SHIPPING_ADVANCED_CAPABILITY = {
  key: 'shipping-advanced',
  group: SHIPPING_CAPABILITY_GROUP,
  limit: SHIPPING_ZONES_ADVANCED_LIMIT
};
export const SHIPPING_BASIC_CAPABILITY = {
  key: 'shipping-basic',
  group: SHIPPING_CAPABILITY_GROUP,
  limit: SHIPPING_ZONES_BASIC_LIMIT
};

// MESSAGES
export const SHIPPING_RATES_ADVANCED_CAPABILITY = {
  key: 'shipping-rates-advanced',
  group: SHIPPING_RATES_CAPABILITY_GROUP
};

// CURRENCY
export const CURRENCY_ADVANCED_CAPABILITY = {
  key: 'currency-advanced',
  group: CURRENCY_CAPABILITY_GROUP
};

// CUSTOMERS
export const CUSTOMERS_ADVANCED_CAPABILITY = {
  key: 'customers-advanced',
  group: CUSTOMERS_CAPABILITY_GROUP
};

// MESSAGES
export const MESSAGES_ADVANCED_CAPABILITY = {
  key: 'messages-advanced',
  group: CUSTOMERS_CAPABILITY_GROUP
};

// Plans
export const BASIC = 'basic';
export const ADVANCED = 'advanced';
