import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

LogoSingleText.propTypes = {
  variant: PropTypes.oneOf(['primary', 'secondary', 'white'])
};

export default function LogoSingleText({ variant, sx }) {
  const theme = useTheme();

  const getColour = () => {
    if (variant === 'primary') {
      return {
        main: theme.palette.primary.main,
        contrast: theme.palette.primary.lighter
      };
    }
    if (variant === 'secondary') {
      return {
        main: theme.palette.secondary.main,
        contrast: theme.palette.secondary.lighter
      };
    }
    if (variant === 'white') {
      return {
        main: theme.palette.common.white,
        contrast: theme.palette.common.white
      };
    }
    return {
      main: theme.palette.common.white,
      contrast: theme.palette.common.white
    };
  };

  const colour = getColour();

  return (
    <Box sx={{ height: 30, maxWidth: 150, ...sx }}>
      <svg
        height="100%"
        width="100%"
        zoomAndPan="magnify"
        viewBox="0 0 5162.25 1145.999944"
        preserveAspectRatio="xMidYMid meet"
      >
        <defs>
          <g />
        </defs>
        <path
          fill={colour.contrast}
          d="M 1762.308594 941.191406 C 1762.308594 937.210938 1762.472656 933.269531 1762.78125 929.375 C 1764.507812 907.679688 1791.09375 898.917969 1806.667969 914.117188 C 1833.082031 939.878906 1869.195312 955.757812 1909.027344 955.757812 C 1948.847656 955.757812 1984.9375 939.902344 2011.34375 914.15625 C 2027.089844 898.808594 2053.65625 907.984375 2055.320312 929.910156 C 2055.96875 938.394531 2055.890625 947.085938 2055.035156 955.9375 C 2048.496094 1023.367188 1994.640625 1078.652344 1927.394531 1086.800781 C 1838.097656 1097.609375 1762.308594 1028.3125 1762.308594 941.191406 "
          fillOpacity={1}
          fillRule="nonzero"
        />
        <g fill={colour.contrast} fillOpacity={1}>
          <g transform="translate(2588.082009, 721.884436)">
            <g>
              <path d="M 59.039062 0 L 109.976562 0 L 109.976562 -310.253906 L 244.265625 -107.664062 L 285.941406 -107.664062 L 420.230469 -309.675781 L 420.230469 0 L 471.167969 0 L 471.167969 -405.179688 L 423.703125 -405.179688 L 265.683594 -164.964844 L 106.503906 -405.179688 L 59.039062 -405.179688 Z M 59.039062 0 " />
            </g>
          </g>
        </g>
        <g fill={colour.contrast} fillOpacity={1}>
          <g transform="translate(3118.289693, 721.884436)">
            <g>
              <path d="M 189.277344 7.523438 C 282.46875 7.523438 348.457031 -58.460938 348.457031 -150.496094 C 348.457031 -242.53125 282.46875 -307.9375 189.277344 -307.9375 C 96.085938 -307.9375 30.097656 -242.53125 30.097656 -150.496094 C 30.097656 -58.460938 96.085938 7.523438 189.277344 7.523438 Z M 78.722656 -150.496094 C 78.722656 -215.902344 124.449219 -261.632812 189.277344 -261.632812 C 254.105469 -261.632812 299.835938 -215.902344 299.835938 -150.496094 C 299.835938 -84.507812 254.105469 -39.359375 189.277344 -39.359375 C 124.449219 -39.359375 78.722656 -84.507812 78.722656 -150.496094 Z M 78.722656 -150.496094 " />
            </g>
          </g>
        </g>
        <g fill={colour.contrast} fillOpacity={1}>
          <g transform="translate(3496.84409, 721.884436)">
            <g>
              <path d="M 53.832031 0 L 102.453125 0 L 102.453125 -151.652344 C 102.453125 -217.640625 142.390625 -261.632812 198.539062 -261.632812 C 251.210938 -261.632812 283.625 -223.429688 283.625 -162.652344 L 283.625 0 L 332.25 0 L 332.25 -167.28125 C 332.25 -252.949219 284.785156 -307.9375 204.90625 -307.9375 C 164.386719 -307.9375 125.027344 -292.308594 98.980469 -256.421875 L 93.769531 -300.414062 L 53.832031 -300.414062 Z M 53.832031 0 " />
            </g>
          </g>
        </g>
        <g fill={colour.contrast} fillOpacity={1}>
          <g transform="translate(3877.134993, 721.884436)">
            <g>
              <path d="M 53.832031 0 L 102.453125 0 L 102.453125 -129.65625 L 138.339844 -129.65625 C 152.230469 -129.65625 164.964844 -130.238281 176.542969 -132.550781 L 259.316406 0 L 317.199219 0 L 223.429688 -148.757812 C 245.425781 -162.652344 261.050781 -185.226562 273.207031 -218.21875 L 303.308594 -300.414062 L 251.210938 -300.414062 L 228.636719 -236.164062 C 211.851562 -187.542969 188.699219 -174.808594 140.078125 -174.808594 L 102.453125 -174.808594 L 102.453125 -428.335938 L 53.832031 -428.335938 Z M 53.832031 0 " />
            </g>
          </g>
        </g>
        <g fill={colour.contrast} fillOpacity={1}>
          <g transform="translate(4214.592414, 721.884436)">
            <g>
              <path d="M 184.066406 7.523438 C 251.792969 7.523438 307.359375 -28.941406 331.667969 -87.402344 L 276.679688 -87.402344 C 258.160156 -55.566406 224.007812 -38.203125 184.648438 -38.203125 C 127.34375 -38.203125 86.824219 -74.089844 79.300781 -130.816406 L 339.195312 -130.816406 C 342.667969 -235.582031 280.734375 -307.9375 184.648438 -307.9375 C 93.769531 -307.9375 30.097656 -243.109375 30.097656 -150.496094 C 30.097656 -57.304688 93.191406 7.523438 184.066406 7.523438 Z M 79.878906 -173.648438 C 88.5625 -226.902344 129.078125 -262.210938 184.648438 -262.210938 C 239.636719 -262.210938 280.152344 -228.636719 290.574219 -173.648438 Z M 79.878906 -173.648438 " />
            </g>
          </g>
        </g>
        <g fill={colour.contrast} fillOpacity={1}>
          <g transform="translate(4582.72785, 721.884436)">
            <g>
              <path d="M 43.414062 115.765625 L 77.5625 115.765625 C 133.710938 115.765625 160.914062 101.875 181.753906 46.886719 L 319.515625 -300.414062 L 268 -300.414062 L 211.851562 -158.597656 L 175.386719 -65.40625 L 131.394531 -158.597656 L 63.671875 -300.414062 L 9.261719 -300.414062 L 151.652344 -6.945312 L 137.761719 28.363281 C 125.027344 61.933594 111.714844 70.039062 72.933594 70.039062 L 43.414062 70.039062 Z M 43.414062 115.765625 " />
            </g>
          </g>
        </g>
        <g fill={colour.main} fillOpacity={1}>
          <g transform="translate(173.460807, 722.626027)">
            <g>
              <path d="M 189.648438 8.09375 C 285.628906 8.09375 344.605469 -37.582031 344.605469 -113.90625 C 344.605469 -178.085938 302.398438 -216.246094 201.789062 -239.953125 C 139.921875 -254.40625 118.53125 -270.015625 118.53125 -294.878906 C 118.53125 -321.476562 142.234375 -337.667969 180.976562 -337.667969 C 223.183594 -337.667969 248.625 -315.695312 248.625 -279.269531 L 337.089844 -279.269531 C 337.089844 -360.792969 275.800781 -412.832031 179.820312 -412.832031 C 86.730469 -412.832031 28.910156 -367.15625 28.910156 -293.144531 C 28.910156 -227.230469 73.429688 -187.914062 168.253906 -167.675781 C 225.496094 -155.535156 251.515625 -138.765625 251.515625 -110.433594 C 251.515625 -83.261719 227.230469 -67.648438 189.070312 -67.648438 C 144.550781 -67.648438 115.640625 -90.199219 115.640625 -128.9375 L 25.441406 -128.9375 C 25.441406 -45.097656 87.886719 8.09375 189.648438 8.09375 Z M 189.648438 8.09375 " />
            </g>
          </g>
        </g>
        <g fill={colour.main} fillOpacity={1}>
          <g transform="translate(544.083736, 722.626027)">
            <g>
              <path d="M 43.941406 0 L 131.828125 0 L 131.828125 -159.582031 C 131.828125 -207.574219 158.425781 -236.480469 198.898438 -236.480469 C 236.480469 -236.480469 259.03125 -211.042969 259.03125 -166.519531 L 259.03125 0 L 346.917969 0 L 346.917969 -175.195312 C 346.917969 -259.609375 299.503906 -311.648438 220.871094 -311.648438 C 186.757812 -311.648438 154.957031 -301.238281 131.828125 -280.425781 L 131.828125 -427.863281 L 43.941406 -427.863281 Z M 43.941406 0 " />
            </g>
          </g>
        </g>
        <g fill={colour.main} fillOpacity={1}>
          <g transform="translate(929.161609, 722.626027)">
            <g>
              <path d="M 193.117188 8.09375 C 293.722656 8.09375 363.105469 -57.242188 363.105469 -152.066406 C 363.105469 -246.3125 293.722656 -311.648438 193.117188 -311.648438 C 91.933594 -311.648438 22.550781 -246.3125 22.550781 -152.066406 C 22.550781 -57.242188 91.933594 8.09375 193.117188 8.09375 Z M 109.277344 -152.066406 C 109.277344 -201.789062 143.972656 -236.480469 193.117188 -236.480469 C 242.265625 -236.480469 276.378906 -201.789062 276.378906 -152.066406 C 276.378906 -102.339844 242.265625 -67.648438 193.117188 -67.648438 C 143.972656 -67.648438 109.277344 -102.339844 109.277344 -152.066406 Z M 109.277344 -152.066406 " />
            </g>
          </g>
        </g>
        <g fill={colour.main} fillOpacity={1}>
          <g transform="translate(1314.817647, 722.626027)">
            <g>
              <path d="M 227.808594 8.09375 C 318.585938 8.09375 382.765625 -57.820312 382.765625 -152.066406 C 382.765625 -245.15625 318.585938 -311.648438 227.808594 -311.648438 C 183.867188 -311.648438 146.285156 -296.035156 119.109375 -268.859375 L 109.277344 -303.554688 L 43.941406 -303.554688 L 43.941406 115.640625 L 131.828125 115.640625 L 131.828125 -23.707031 C 157.269531 -3.46875 190.226562 8.09375 227.808594 8.09375 Z M 128.359375 -152.066406 C 128.359375 -201.210938 163.050781 -236.480469 212.199219 -236.480469 C 261.921875 -236.480469 296.035156 -201.210938 296.035156 -152.066406 C 296.035156 -102.339844 261.921875 -67.648438 212.199219 -67.648438 C 163.050781 -67.648438 128.359375 -102.339844 128.359375 -152.066406 Z M 128.359375 -152.066406 " />
            </g>
          </g>
        </g>
        <g fill={colour.main} fillOpacity={1}>
          <g transform="translate(1720.132282, 722.626027)">
            <g>
              <path d="M 227.808594 8.09375 C 318.585938 8.09375 382.765625 -57.820312 382.765625 -152.066406 C 382.765625 -245.15625 318.585938 -311.648438 227.808594 -311.648438 C 183.867188 -311.648438 146.285156 -296.035156 119.109375 -268.859375 L 109.277344 -303.554688 L 43.941406 -303.554688 L 43.941406 115.640625 L 131.828125 115.640625 L 131.828125 -23.707031 C 157.269531 -3.46875 190.226562 8.09375 227.808594 8.09375 Z M 128.359375 -152.066406 C 128.359375 -201.210938 163.050781 -236.480469 212.199219 -236.480469 C 261.921875 -236.480469 296.035156 -201.210938 296.035156 -152.066406 C 296.035156 -102.339844 261.921875 -67.648438 212.199219 -67.648438 C 163.050781 -67.648438 128.359375 -102.339844 128.359375 -152.066406 Z M 128.359375 -152.066406 " />
            </g>
          </g>
        </g>
        <g fill={colour.main} fillOpacity={1}>
          <g transform="translate(2125.446916, 722.626027)">
            <g>
              <path d="M 45.097656 115.640625 L 86.152344 115.640625 C 156.691406 115.640625 186.179688 98.292969 211.042969 35.847656 L 346.339844 -303.554688 L 252.09375 -303.554688 L 211.621094 -193.117188 L 183.867188 -115.640625 L 102.339844 -303.554688 L 5.203125 -303.554688 L 141.65625 -9.828125 L 138.1875 0.578125 C 127.78125 30.644531 115.640625 39.894531 79.792969 39.894531 L 45.097656 39.894531 Z M 45.097656 115.640625 " />
            </g>
          </g>
        </g>
        <g fill={colour.main} fillOpacity={1}>
          <g transform="translate(2476.411301, 722.626027)">
            <g />
          </g>
        </g>
      </svg>
    </Box>
  );
}
