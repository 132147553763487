import { Stack, Skeleton, Box } from '@material-ui/core';

export const PaymentComponentSkeleton = () => (
  <Box sx={{ width: '100%' }}>
    <Stack spacing={2} sx={{ maxWidth: 500, margin: '0 auto' }}>
      <Stack direction="row" spacing={2}>
        <Skeleton variant="rectangular" width="50%" sx={{ height: 30, borderRadius: 1 }} />
        <Skeleton variant="rectangular" width="50%" sx={{ height: 30, borderRadius: 1 }} />
      </Stack>
      <Skeleton variant="rectangular" width="100%" sx={{ height: 30, borderRadius: 1 }} />
      <Skeleton variant="rectangular" width="100%" sx={{ height: 30, borderRadius: 1 }} />
    </Stack>
  </Box>
);
