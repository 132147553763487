import { useCallback, useEffect, useRef } from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Icon } from '@iconify/react';
import alertCircleFill from '@iconify/icons-eva/alert-circle-fill';
import { Box, Button, Container, Grid, Paper, Stack, Typography } from '@material-ui/core';
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';
import { getPlan } from 'src/redux/slices/plan';
import { lowerCase } from 'lodash';
import { PaymentComponentSkeleton } from 'src/components/_admin_v2/subscription/PaymentComponentSkeleton';
import GenericComponentError from 'src/components/_admin_v2/GenericComponentError';
import { SupportCard } from 'src/components/_admin_v2/setting';
import { SkeletonLoadingSmall } from 'src/components/_admin_v2/SkeletonLoadingSmall';
import { StripePaymentComponent } from 'src/components/_admin_v2/subscription/StripePaymentComponent';
import { PlansPaymentSupportCard } from 'src/components/_admin_v2/subscription/PlansPaymentSupportCard';
import { PricingPlanRootStyle } from 'src/components/_admin_v2/subscription/PricingPlanCard';
import { useDispatch, useSelector } from '../../redux/store';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
import useSettings from '../../hooks/useSettings';
// components
import Page from '../../components/Page';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';

export const PAYMENT_STATUS_KEY = 'status';
export const PAYMENT_INTENT_SETUP_KEY = 'setup_intent';
export const PAYMENT_INTENT_SETUP_SECRET_KEY = 'setup_intent_client_secret';

export const PlansPaymentView = ({ planId: id, isComplete }) => {
  const dispatch = useDispatch();

  const {
    loading: { planLoading: isLoading },
    error: { planError: hasError },
    plan
  } = useSelector((state) => state.plan);

  const planLoaded = useRef(false);

  useEffect(() => {
    if (id && !isLoading && !planLoaded.current) {
      dispatch(getPlan(id));
      planLoaded.current = true;
    }
  }, [id, isLoading, dispatch]);

  const getPaymentProps = useCallback(() => {
    if (plan && !isLoading) {
      const { id, currency } = plan;
      const { code } = currency;
      const currencyCode = lowerCase(code);
      return {
        priceId: id,
        currency: currencyCode
      };
    }
    return null;
  }, [plan, isLoading]);

  const PaymentCardView = () => {
    if (isComplete) {
      return (
        <Paper sx={{ p: 2 }}>
          <PricingPlanRootStyle
            sx={{
              boxShadow: 'none',
              border: (theme) => `1px solid ${theme.palette.divider}`,
              minHeight: 'unset',
              maxWidth: '100%'
            }}
          >
            <Stack spacing={2} alignItems="center">
              <CheckCircleIcon sx={{ color: (theme) => theme.palette.success.main, fontSize: 100 }} />
              <Typography variant="h5" gutterBottom>
                Your Subscription Was Successful
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                You don't have to anything more. You are now all set up.
              </Typography>
            </Stack>
          </PricingPlanRootStyle>
        </Paper>
      );
    }

    if (plan.subscribedToPlan) {
      return (
        <Paper sx={{ p: 2 }}>
          <PricingPlanRootStyle
            sx={{
              boxShadow: 'none',
              border: (theme) => `1px solid ${theme.palette.divider}`,
              minHeight: 'unset',
              maxWidth: '100%'
            }}
          >
            <Stack spacing={2} alignItems="center">
              <Box sx={{ color: (theme) => theme.palette.warning.main }}>
                <Icon icon={alertCircleFill} width={100} />
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography variant="h5" gutterBottom>
                  No Action Required
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  You cannot subscribe to your current plan.
                </Typography>
              </Box>
            </Stack>
          </PricingPlanRootStyle>
        </Paper>
      );
    }
    return <StripePaymentComponent {...getPaymentProps()} />;
  };

  return (
    <>
      {hasError && (
        <GenericComponentError description="At this moment we cannot facilitate this payment. Please try again in shortly." />
      )}
      <Box>
        {!hasError && (
          <Grid container spacing={3}>
            <Grid item xs={12} md={5}>
              <SupportCard>
                {isLoading ? <SkeletonLoadingSmall /> : <PlansPaymentSupportCard plan={plan} />}
              </SupportCard>
            </Grid>
            <Grid item xs={12} md={7}>
              {isLoading && <PaymentComponentSkeleton />}
              {!isLoading && plan && <PaymentCardView />}
            </Grid>
          </Grid>
        )}
      </Box>
    </>
  );
};

export default function PlansPayment() {
  const { themeStretch } = useSettings();
  const { id } = useParams();
  const { search } = useLocation();
  // STATUS APPENDED PARAM
  const queryParams = new URLSearchParams(search);
  const status = queryParams.get(PAYMENT_STATUS_KEY);
  const piSetup = queryParams.get(PAYMENT_INTENT_SETUP_KEY);
  const piSetupSecret = queryParams.get(PAYMENT_INTENT_SETUP_SECRET_KEY);
  const isComplete = status === 'success' && Boolean(piSetup) && Boolean(piSetupSecret);

  return (
    <Page title="Plans | Payment">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Complete Payment"
          links={[
            { name: 'Home', href: PATH_DASHBOARD.root },
            { name: 'Plans', href: PATH_DASHBOARD.plans.root },
            { name: 'Payment' }
          ]}
          {...(isComplete && {
            action: (
              <Button variant="contained" component={RouterLink} to={PATH_DASHBOARD.setting.plan}>
                View Current Plan
              </Button>
            )
          })}
        />

        <PlansPaymentView planId={id} isComplete={isComplete} />
      </Container>
    </Page>
  );
}
