import { styled } from '@material-ui/core/styles';
import { Stack, Typography, Box, Divider } from '@material-ui/core';
import { capitalCase } from 'capital-case';
import { fNumberCurrency } from 'src/utils/formatNumber';

const StatementCard = styled(Box)(({ theme }) => ({
  '&&': {
    margin: theme.spacing(2, 0, 0, 0)
  }
}));

const ListItem = ({ name, value, currency }) => (
  <Stack direction="row" spacing={2}>
    <Typography variant="body2" sx={{ width: '100%' }}>
      {capitalCase(name)}
    </Typography>
    <Typography variant="body2" sx={{ width: '100%', textAlign: 'right' }}>
      {fNumberCurrency(value, currency)}
    </Typography>
  </Stack>
);

const Statement = ({ currency, items, total }) => (
  <StatementCard>
    <Stack direction="column" spacing={1}>
      {items.map((_item, index) => (
        <ListItem key={index} name={_item.name} value={_item.amount} currency={currency} />
      ))}
      <Divider orientation="horizontal" />
      <ListItem name="total" value={total} currency={currency} />
    </Stack>
  </StatementCard>
);

export default Statement;
