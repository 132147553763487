import { NavLink as RouterLink, useLocation } from 'react-router-dom';
// material
import { styled } from '@material-ui/core/styles';
import { Box, Button, AppBar, Toolbar, Container, Stack } from '@material-ui/core';
// hooks
import useAuth from 'src/hooks/useAuth';
import { PATH_AUTH, PATH_DASHBOARD, PATH_PAGE } from 'src/routes/paths';
import useMobile from 'src/hooks/useMobile';
import { customShadows } from 'src/theme/shadows';
import Logo from 'src/components/Logo';
import useOffSetTop from '../../hooks/useOffSetTop';
import { MHidden } from '../../components/@material-extend';
//
import MenuDesktop from './MenuDesktop';
import MenuMobile from './MenuMobile';
import getMenuConfig from './MenuConfig';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 88;

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  height: APP_BAR_MOBILE,
  transition: theme.transitions.create(['height', 'background-color'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  [theme.breakpoints.up('md')]: {
    height: APP_BAR_DESKTOP
  }
}));

const ToolbarShadowStyle = styled('div')(({ theme }) => ({
  left: 0,
  right: 0,
  bottom: 0,
  height: 24,
  zIndex: -1,
  margin: 'auto',
  borderRadius: '50%',
  position: 'absolute',
  width: `calc(100% - 48px)`,
  boxShadow: theme.customShadows.z8
}));

const FULL_SCREEN_PATHS = [PATH_PAGE.home, PATH_PAGE.info.ecommerce.overview, PATH_PAGE.info.ecommerce.currencies];

export default function MainNavbar() {
  const isOffset = useOffSetTop(0);
  const { pathname } = useLocation();
  const isMobile = useMobile();
  const { isAuthenticated } = useAuth();
  const isFullscreen = FULL_SCREEN_PATHS.includes(pathname);
  const isHome = pathname === PATH_PAGE.home;
  const isDark = pathname.includes(PATH_PAGE.info.ecommerce.root);
  const navConfig = getMenuConfig(isAuthenticated);
  const whiteThemeLogo = {
    variant: 'white',
    contrast: 'primary'
  };
  const defaultThemeLogo = {
    variant: 'white',
    contrast: 'primary',
    background: 'primary'
  };

  return (
    <AppBar sx={{ boxShadow: 0, bgcolor: 'transparent' }}>
      <ToolbarStyle
        disableGutters
        sx={{
          ...(isOffset && {
            bgcolor: 'background.default',
            height: { md: APP_BAR_DESKTOP - 16 },
            ...(isDark && {
              bgcolor: 'grey.800'
            })
          })
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <Stack direction="row" spacing={isMobile ? 0 : 6} sx={{ alignItems: 'center', width: '100%' }}>
            <Box>
              <RouterLink to={PATH_PAGE.home}>
                <Logo
                  logo={{
                    ...(isHome && {
                      ...(isOffset && whiteThemeLogo),
                      ...(isOffset && defaultThemeLogo)
                    }),
                    ...(!isHome && {
                      ...(Boolean(isOffset && isDark) && {
                        ...whiteThemeLogo,
                        contrast: '#000'
                      }),
                      ...(Boolean(!isOffset && isDark) && {
                        ...whiteThemeLogo,
                        contrast: 'green'
                      }),
                      ...(Boolean(isOffset && !isDark) && defaultThemeLogo),
                      ...(Boolean(!isOffset && !isDark) && defaultThemeLogo)
                    })
                  }}
                  text={{
                    variant: 'white',
                    ...(isHome && {
                      ...(isOffset && { variant: 'primary' })
                    }),
                    ...(!isHome && {
                      ...(Boolean(!isDark) && { variant: 'primary' })
                    })
                  }}
                />
              </RouterLink>
            </Box>

            <MHidden width="mdDown">
              <MenuDesktop isOffset={isOffset} isFullscreen={isFullscreen} isDark={isDark} navConfig={navConfig} />
            </MHidden>
            <Box sx={{ flexGrow: 1 }} />
            {!isMobile && (
              <Stack spacing={3} direction="row" sx={{ color: isOffset ? 'text.primary' : 'common.white' }}>
                {isAuthenticated ? (
                  <Button LinkComponent={RouterLink} variant="contained" to={PATH_DASHBOARD.root}>
                    Dashboard
                  </Button>
                ) : (
                  <>
                    <Button
                      LinkComponent={RouterLink}
                      variant="text"
                      to={PATH_AUTH.login}
                      sx={{
                        color: 'text.primary',
                        ...(isFullscreen && { color: 'common.white' }),
                        ...(isOffset && {
                          ...(isFullscreen && { color: 'text.primary' }),
                          ...(isDark && { color: 'common.white' })
                        })
                      }}
                    >
                      Login
                    </Button>
                    <Button LinkComponent={RouterLink} variant="contained" to={PATH_AUTH.signup}>
                      Start free trial
                    </Button>
                  </>
                )}
              </Stack>
            )}

            <MHidden width="mdUp">
              <MenuMobile
                authenticated={isAuthenticated}
                isOffset={isOffset}
                isFullscreen={isFullscreen}
                isDark={isDark}
                navConfig={navConfig}
              />
            </MHidden>
          </Stack>
        </Container>
      </ToolbarStyle>

      {isOffset && <ToolbarShadowStyle sx={{ ...(isDark && { boxShadow: customShadows.dark.z8 }) }} />}
    </AppBar>
  );
}
