import { orderBy } from 'lodash';

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  const update = result.map((obj, index) => {
    return {
      ...obj,
      order: index
    };
  });
  return update;
};

export const orderComponents = (components) => orderBy(components, 'order', 'asc');

// Increment order and swap with next value
export const incrementComponentOrder = (list, id) => {
  const result = orderComponents(list);
  const index = result.findIndex((_component) => _component.id === id);
  if (index > 0 && index <= result.length - 1) {
    const nextIndex = index - 1;
    const nextOrder = result[nextIndex].order;
    const currentOrder = result[index].order;
    result[index].order = nextOrder;
    result[nextIndex].order = currentOrder;
    return orderComponents(result);
  }
  return result;
};

export const decreaseComponentOrder = (list, id) => {
  const result = orderComponents(list);
  const index = result.findIndex((_component) => _component.id === id);
  if (index >= 0 && index < result.length - 1) {
    const nextIndex = index + 1;
    const nextOrder = result[nextIndex].order;
    const currentOrder = result[index].order;
    result[index].order = nextOrder;
    result[nextIndex].order = currentOrder;
    return orderComponents(result);
  }
  return result;
};

export const removeComponent = (list, id) => {
  const result = orderComponents(list);
  const index = result.findIndex((_component) => _component.id === id);
  if (index !== -1) {
    result.splice(index, 1);
    // Update order for remaining values to sync in order
    result.forEach((value, index) => (value.order = index + 1));
    return orderComponents(result);
  }
  return result;
};
