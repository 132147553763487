import { paramCase } from 'change-case';
import { defaultTo, max } from 'lodash';
import {
  ADVANCED,
  BASIC,
  CURRENCY_ADVANCED_CAPABILITY,
  REPORTS_ADVANCED_CAPABILITY,
  REPORTS_BASIC_CAPABILITY,
  REPORTS_LIST_PAGE,
  SHIPPING_ADVANCED_CAPABILITY,
  SHIPPING_BASIC_CAPABILITY,
  USERS_ADVANCED_CAPABILITY,
  USERS_BASIC_CAPABILITY,
  MESSAGES_ADVANCED_CAPABILITY,
  MESSAGES_PAGE,
  SHIPPING_RATES_ADVANCED_CAPABILITY,
  CUSTOMERS_ADVANCED_CAPABILITY,
  CUSTOMERS_DETAIL_PAGE
} from './capabilityConstants';

export const isAdvanced = (tier) => paramCase(defaultTo(tier, '')) === 'advanced';

const ADVANCED_CAPABILITIES = [
  REPORTS_ADVANCED_CAPABILITY,
  USERS_ADVANCED_CAPABILITY,
  SHIPPING_ADVANCED_CAPABILITY,
  CURRENCY_ADVANCED_CAPABILITY,
  CUSTOMERS_ADVANCED_CAPABILITY,
  MESSAGES_ADVANCED_CAPABILITY,
  SHIPPING_RATES_ADVANCED_CAPABILITY
];

// What capabilities are available for the given tier
export const getTierCapabilities = (value) => {
  const tier = paramCase(defaultTo(value, ''));

  if (tier === ADVANCED) {
    return ADVANCED_CAPABILITIES;
  }
  if (tier === BASIC) {
    return [REPORTS_BASIC_CAPABILITY, USERS_BASIC_CAPABILITY, SHIPPING_BASIC_CAPABILITY];
  }
  // TRIAL - Needs to have all offers before signing up
  return ADVANCED_CAPABILITIES;
};

// Who can access a given page (capabilties)
export const getPageCapabilities = (resource) => {
  if (!resource) {
    return [];
  }
  if (resource === REPORTS_LIST_PAGE) {
    return [REPORTS_ADVANCED_CAPABILITY.key];
  }
  if (resource === CUSTOMERS_DETAIL_PAGE) {
    return [CUSTOMERS_ADVANCED_CAPABILITY.key];
  }
  if (resource === MESSAGES_PAGE) {
    return [MESSAGES_ADVANCED_CAPABILITY.key];
  }
  return [];
};

export const isCapabilityLimitViolated = (input, limit) => defaultTo(input, 0) >= defaultTo(limit, 0);

export const calculateMaxValue = (value, limit) => max([(value * 100) / limit, 0]);
