import { Switch, FormControlLabel, FormHelperText, Typography } from '@material-ui/core';
import { capitalCase } from 'capital-case';

export default function FormCheckBox({ description, touched, errors, value, name, labelPlacement = 'end', ...rest }) {
  return (
    <div>
      <FormControlLabel
        label={capitalCase(name)}
        control={<Switch name={name} size="medium" value={value} checked={value} {...rest} />}
        labelPlacement={labelPlacement}
      />
      {description && (
        <Typography component="div" variant="caption" color="text.secondary">
          {description}
        </Typography>
      )}
      {Boolean(touched[name] && errors[name]) && <FormHelperText error>{errors[name]}</FormHelperText>}
    </div>
  );
}
