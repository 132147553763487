import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import useAuth from 'src/hooks/useAuth';
import { useSnackbar } from 'notistack5';
import { PATH_AUTH } from 'src/routes/paths';
import axios from '../utils/axios';

const UNAUTHORISED = 401;
const AxiosInterceptor = ({ children }) => {
  const navigate = useNavigate();
  const { logout, isAuthenticated } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  /*
    If upon making a request the response status is 401 then we need to also log user out as token has expired.
  */
  useEffect(() => {
    const interceptor = axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        if (error.response && error.response.status === UNAUTHORISED && isAuthenticated) {
          await logout();
          enqueueSnackbar('Session expired. Please log back in.', {
            variant: 'error'
          });
          navigate(PATH_AUTH.login);
        }
        if ([400, 404].includes(error.response.status)) {
          return Promise.reject(error.response.data?.detail || 'Something went wrong');
        }
        return Promise.reject(error);
      }
    );
    return () => axios.interceptors.response.eject(interceptor);
  }, [logout, navigate, enqueueSnackbar, isAuthenticated]);
  return children;
};

export default AxiosInterceptor;
