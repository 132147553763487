import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  loading: {
    industriesLoading: false,
    countriesLoading: false
  },
  error: {
    industriesError: null,
    countriesError: null
  },
  tenant: null,
  options: {
    industry: [],
    countries: []
  }
};

const slice = createSlice({
  name: 'website',
  initialState,
  reducers: {
    // START LOADING
    startCountriesLoading(state) {
      state.loading.countriesLoading = true;
    },
    startIndustriesLoading(state) {
      state.loading.industriesLoading = true;
    },

    // HAS ERROR
    hasCountriesError(state, action) {
      state.loading.countriesLoading = false;
      state.error.countriesError = action.payload;
    },
    hasIndustriesError(state, action) {
      state.loading.industriesLoading = false;
      state.error.industriesError = action.payload;
    },

    getCountriesOptionsSuccess(state, action) {
      state.loading.countriesLoading = false;
      state.options.countries = action.payload;
    },
    getIndustryOptionsSuccess(state, action) {
      state.loading.industriesLoading = false;
      state.options.industry = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

// GET OPTIONS

export function getIndustryOptions() {
  return async (dispatch) => {
    dispatch(slice.actions.startIndustriesLoading());
    try {
      const response = await axios.get('/website/options/industries');
      dispatch(slice.actions.getIndustryOptionsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasIndustriesError(error));
    }
  };
}

export function getCountryOptions() {
  return async (dispatch) => {
    dispatch(slice.actions.startCountriesLoading());
    try {
      const response = await axios.get('/website/options/countries');
      dispatch(slice.actions.getCountriesOptionsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasCountriesError(error));
    }
  };
}

// ----------------------------------------------------------------------

// REGISTRATION

export const sendEmailSecurityCode = async (firstName, lastName, email) => {
  const response = await axios.post('/website/sign-up/security', {
    firstName,
    lastName,
    email
  });
  // IDENTIFIER
  return response.data;
};

export const register = async (values, id, code) => {
  const response = await axios.post('/website/sign-up', {
    ...values,
    securityCodeId: id,
    securityCode: code
  });
  return response.data;
};

export const checkRegistrationStatus = async (id) => {
  const response = await axios.get(`/website/sign-up/${id}`);
  return response.data;
};

// RESET PASSWORD

export const resetPassword = async (username, password, securityCodeId, securityCode) => {
  await axios.post(`/website/account/reset-password`, {
    username,
    password,
    securityCodeId,
    securityCode
  });
};

export const sendRestPasswordSecurityCode = async (username) => {
  const response = await axios.post('/website/account/reset-password/security', {
    username
  });
  // // IDENTIFIER
  return response.data;
};

export const verifyRestPasswordSecurityCode = async (id, code) => {
  return axios.post('/website/account/reset-password/security/verify', {
    securityCodeId: id,
    securityCode: code
  });
};
