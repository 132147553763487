import { Stack, Box, Skeleton } from '@material-ui/core';

export const SkeletonLoadingSmall = (props) => (
  <Box {...props}>
    <Stack spacing={2}>
      <Skeleton variant="rectangular" width="100%" sx={{ borderRadius: 2 }} />
      <Skeleton variant="rectangular" width="100%" sx={{ borderRadius: 2 }} />
      <Skeleton variant="rectangular" width="50%" sx={{ borderRadius: 2 }} />
    </Stack>
  </Box>
);
