import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { compact, defaultTo, find, isEmpty } from 'lodash';
import { getPageCapabilities, getTierCapabilities } from 'src/utils/capability';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { CUSTOMERS_DETAIL_PAGE, MESSAGES_PAGE, REPORTS_LIST_PAGE } from 'src/utils/capabilityConstants';
import { isMatch } from 'src/utils/helperRouter';
import { useSelector } from '../redux/store';
import { ReportsAdvancedCapabilityLockScreen } from './components/ReportsAdvancedCapabilityLockScreen';
import { CustomersCapabilityLockScreen } from './components/CustomersCapabilityLockScreen';

const GUARD_COMPONENTS = [
  {
    key: REPORTS_LIST_PAGE,
    component: <ReportsAdvancedCapabilityLockScreen />
  },
  {
    key: CUSTOMERS_DETAIL_PAGE,
    component: <CustomersCapabilityLockScreen />
  },
  {
    key: MESSAGES_PAGE,
    component: <CustomersCapabilityLockScreen />
  }
];

const getComponent = (key) => find(GUARD_COMPONENTS, (o) => o.key === key);

const isGuardedPage = (path) => {
  const resource = defaultTo(path, '');
  if (resource === PATH_DASHBOARD.reports.root) {
    return [true, REPORTS_LIST_PAGE, getComponent(REPORTS_LIST_PAGE).component];
  }
  if (isMatch(resource, CUSTOMERS_DETAIL_PAGE)) {
    return [true, CUSTOMERS_DETAIL_PAGE, getComponent(CUSTOMERS_DETAIL_PAGE).component];
  }
  if (resource.includes(MESSAGES_PAGE)) {
    return [true, MESSAGES_PAGE, getComponent(MESSAGES_PAGE).component];
  }

  return [false];
};

const checkAuthorized = (capabilities, pageCapabilities) => {
  if (isEmpty(pageCapabilities)) {
    return true;
  }
  const keys = capabilities.map((o) => o.key);
  const response = !isEmpty(
    compact(pageCapabilities.map((capability) => (keys.includes(capability) ? capability : null)))
  );
  return response;
};

CapabilityGuard.propTypes = {
  children: PropTypes.node
};

export default function CapabilityGuard({ children }) {
  const { pathname } = useLocation();
  const { tenant } = useSelector((state) => state.tenant);
  const tier = tenant?.plan?.productType;

  // Guard
  const [isGuarded, resource, component] = isGuardedPage(pathname);

  // Capabilities
  const pageCapabilities = getPageCapabilities(resource);
  const tierCapabilities = getTierCapabilities(tier);
  const isAuthorized = checkAuthorized(tierCapabilities, pageCapabilities);

  if (isGuarded && !isAuthorized) {
    return component;
  }

  return <>{children}</>;
}
