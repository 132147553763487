import { useState, useMemo, useCallback } from 'react';
import {
  Typography,
  Button,
  TextField,
  FormHelperText,
  Stack,
  Checkbox,
  FormControlLabel,
  Card,
  Tab,
  Tabs,
  Box
} from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { LoadingButton } from '@material-ui/lab';
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import Scrollbar from 'src/components/Scrollbar';

const selectedChecker = (arr, target) => {
  let increment = 0;
  target.forEach((v) => {
    if (arr.includes(v)) {
      increment += 1;
    }
  });
  return increment;
};

const CountriesCard = styled(Card)(({ theme }) => ({
  width: '100%',
  boxShadow: 'none',
  border: `1px solid ${theme.palette.grey[500_32]}`,
  borderRadius: 8,
  backgroundColor: 'transparent'
}));

const RegionCountries = ({ countries, currentCountryIds, setFieldValue }) => {
  const SelectAll = () => {
    const ids = countries.map((_country) => _country.id);
    const regionMatchCount = selectedChecker(ids, currentCountryIds);
    const isChecked = regionMatchCount >= 1;

    return (
      <Box>
        <FormControlLabel
          label={<Typography variant="body1">All</Typography>}
          control={
            <Checkbox
              checked={isChecked}
              indeterminate={isChecked && regionMatchCount !== ids.length}
              onChange={() => {
                if (!isChecked) {
                  // If `check all` then add region ids to selected
                  const arr = currentCountryIds.concat(ids);
                  setFieldValue('countries', arr, true);
                } else {
                  const arr = currentCountryIds.filter((id) => !ids.includes(id));
                  setFieldValue('countries', arr, true);
                }
              }}
            />
          }
        />
      </Box>
    );
  };

  const countryComponents = countries.map((country, index) => {
    const { id, name } = country;
    const isItemSelected = currentCountryIds.indexOf(id) !== -1;
    return (
      <Box key={index}>
        <FormControlLabel
          label={<Typography variant="body1">{name}</Typography>}
          control={
            <Checkbox
              checked={isItemSelected}
              onChange={() => {
                const selectedIndex = currentCountryIds.indexOf(id);
                let newSelected = [];
                if (selectedIndex === -1) {
                  // Already selected
                  newSelected = newSelected.concat(currentCountryIds, id);
                } else {
                  // Not already selected
                  const filteredList = currentCountryIds.filter((_selected) => _selected !== id);
                  newSelected = newSelected.concat(filteredList);
                }
                setFieldValue('countries', newSelected, true);
              }}
            />
          }
        />
      </Box>
    );
  });
  return (
    <>
      <SelectAll />
      {countryComponents}
    </>
  );
};

const TabCountries = ({
  all: availableCountries,
  setFieldValue,
  value: currentCountryIds,
  tabValue,
  handleChangeScrollable
}) => {
  return (
    <>
      <Tabs
        allowScrollButtonsMobile
        value={tabValue}
        variant="scrollable"
        scrollButtons="auto"
        onChange={(_, newValue) => handleChangeScrollable(newValue)}
      >
        {availableCountries.map((_region, index) => {
          const { name } = _region;
          return <Tab key={String(index)} label={name} value={String(index)} />;
        })}
      </Tabs>
      <CountriesCard sx={{ height: '300px' }}>
        <Scrollbar sx={{ p: 3 }}>
          {availableCountries.map((_region, index) => {
            const { countries } = _region;
            return (
              tabValue === String(index) && (
                <RegionCountries
                  key={index}
                  countries={countries}
                  currentCountryIds={currentCountryIds}
                  setFieldValue={setFieldValue}
                />
              )
            );
          })}
        </Scrollbar>
      </CountriesCard>
    </>
  );
};

export default function ShippingZoneCountries({
  countries: allCountries,
  shippingZone,
  handleSave,
  handleSaveError,
  onClose
}) {
  const [tabValue, setTabValue] = useState('0');

  const ShippingZoneSchema = Yup.object().shape({
    name: Yup.string().required('Shipping zone name is required'),
    countries: Yup.array().min(1, 'At least one country is required')
  });
  const formik = useFormik({
    enableReinitialize: false,
    initialValues: {
      id: shippingZone?.id || '',
      name: shippingZone?.name || '',
      countries: shippingZone?.countries || []
    },

    validationSchema: ShippingZoneSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        await handleSave(values);
        setSubmitting(false);
      } catch (error) {
        handleSaveError();
        setSubmitting(false);
      }
    }
  });

  const handleChangeScrollable = useCallback((newValue) => {
    setTabValue(newValue);
  }, []);

  const { values, isSubmitting, handleSubmit, getFieldProps, setFieldValue, touched, errors } = formik;

  const CountriesMemo = useMemo(
    () => (
      <TabCountries
        tabValue={tabValue}
        all={allCountries}
        setFieldValue={setFieldValue}
        value={values.countries}
        handleChangeScrollable={handleChangeScrollable}
      />
    ),
    [tabValue, allCountries, setFieldValue, values.countries, handleChangeScrollable]
  );

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" onSubmit={handleSubmit}>
        <Stack direction="column" spacing={2}>
          <TextField
            fullWidth
            label="Name"
            {...getFieldProps('name')}
            error={Boolean(touched.name && errors.name)}
            helperText={touched.name && errors.name}
            sx={{ mt: 2 }}
          />
          <Typography variant="subtitle1">Countries</Typography>
          {CountriesMemo}
          {touched.countries && errors.countries && (
            <FormHelperText error>{touched.countries && errors.countries}</FormHelperText>
          )}
          <Box sx={{ textAlign: 'right' }}>
            <Button disabled={isSubmitting} onClick={onClose}>
              Cancel
            </Button>
            <LoadingButton loading={isSubmitting} type="submit" variant="contained" sx={{ ml: 2 }}>
              Save
            </LoadingButton>
          </Box>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
