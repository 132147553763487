import { Icon } from '@iconify/react';
import settings2Fill from '@iconify/icons-eva/settings-2-fill';
import certificate20Filled from '@iconify/icons-fluent/certificate-20-filled';
import payment16Filled from '@iconify/icons-fluent/payment-16-filled';
import peopleFill from '@iconify/icons-eva/people-fill';
import vanUtility from '@iconify/icons-mdi/van-utility';
import attachFill from '@iconify/icons-eva/attach-fill';
import bellFill from '@iconify/icons-eva/bell-fill';
import globeIcon from '@iconify/icons-mdi/globe';
import markEmailUnreadOutlineRounded from '@iconify/icons-material-symbols/mark-email-unread-outline-rounded';
import boxFilled from '@iconify/icons-fluent/box-24-filled';
import { PATH_DASHBOARD } from '../../routes/paths';

const getSettingsSidebarConfig = () => {
  return [
    // Store
    {
      subheader: 'Store Settings',
      items: [
        {
          title: 'General',
          path: PATH_DASHBOARD.setting.general,
          icon: <Icon fontSize={20} icon={settings2Fill} />
        },
        {
          title: 'Plan',
          path: PATH_DASHBOARD.setting.plan,
          icon: <Icon fontSize={20} icon={certificate20Filled} />
        },
        {
          title: 'Users and permissions',
          path: PATH_DASHBOARD.setting.users,
          icon: <Icon fontSize={20} icon={peopleFill} />
        },
        {
          title: 'Shipping',
          path: PATH_DASHBOARD.setting.shipping,
          icon: <Icon fontSize={20} icon={vanUtility} />
        },
        {
          title: 'Domain',
          path: PATH_DASHBOARD.setting.domains,
          icon: <Icon fontSize={20} icon={globeIcon} />
        },
        {
          title: 'Files',
          path: PATH_DASHBOARD.setting.files,
          icon: <Icon fontSize={20} icon={attachFill} />
        },
        {
          title: 'Notifications',
          path: PATH_DASHBOARD.setting.notifications,
          icon: <Icon fontSize={20} icon={bellFill} />
        }
      ]
    },
    {
      subheader: 'Integrations',
      items: [
        {
          title: 'Payments',
          path: PATH_DASHBOARD.setting.payments,
          icon: <Icon fontSize={20} icon={payment16Filled} />
        },
        {
          title: 'Messaging',
          path: PATH_DASHBOARD.setting.messaging,
          icon: <Icon fontSize={20} icon={markEmailUnreadOutlineRounded} />
        },
        {
          title: 'Dropshipping',
          path: PATH_DASHBOARD.setting.dropshipping,
          icon: <Icon fontSize={20} icon={boxFilled} />
        }
      ]
    }
  ];
};

export default getSettingsSidebarConfig;
