import { Box } from '@material-ui/core';
import { Outlet } from 'react-router-dom';
import GoogleAnalyticsTrackPage from 'src/components/GoogleAnalyticsTrackPage';
import MainNavbar from './MainNavbar';
import MainFooter from './MainFooter';

// ----------------------------------------------------------------------

export default function MainLayout() {
  return (
    <GoogleAnalyticsTrackPage>
      <MainNavbar />
      <Box sx={{ minHeight: '100%' }}>
        <Outlet />
      </Box>

      <MainFooter />
    </GoogleAnalyticsTrackPage>
  );
}
