import { paramCase } from 'change-case';
import { ADVANCED, BASIC } from 'src/utils/capabilityConstants';
import { Box } from '@material-ui/core';
import Label from '../Label';

export const CapabilityPlanLabel = ({ tier }) => (
  <Box>
    <Label variant="ghost" color={paramCase(tier) === paramCase(ADVANCED) ? 'warning' : 'success'}>
      {paramCase(tier) === paramCase(ADVANCED) && 'Advanced Plan'}
      {paramCase(tier) === paramCase(BASIC) && 'Basic Plan'}
    </Label>
  </Box>
);
