import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import initial from './initial';

// ----------------------------------------------------------------------

const initialState = {
  loading: {
    discountsIsLoading: false,
    discountIsLoading: false
  },
  error: {
    discountsError: null,
    discountError: null
  },
  discounts: initial.pagableData,
  discount: null
};

const slice = createSlice({
  name: 'discount',
  initialState,
  reducers: {
    // START LOADING
    startDiscountsLoading(state) {
      state.loading.discountsIsLoading = true;
    },
    startDiscountLoading(state) {
      state.loading.discountIsLoading = true;
    },

    // HAS ERROR
    hasDiscountsError(state, action) {
      state.loading.discountsIsLoading = false;
      state.error.discountsError = action.payload;
    },
    hasDiscountError(state, action) {
      state.loading.discountIsLoading = false;
      state.error.discountError = action.payload;
    },

    // GET PRODUCTS
    getDiscountsSuccess(state, action) {
      state.loading.discountsIsLoading = false;
      state.error.discountsError = null;
      state.discounts = action.payload;
    },
    getDiscountSuccess(state, action) {
      state.loading.discountIsLoading = false;
      state.error.discountError = null;
      state.discount = action.payload;
    },

    // RESET
    resetDiscounts(state) {
      state.loading.discountsIsLoading = false;
      state.discounts = initial.pagableData;
      state.error.discountsError = null;
    },
    resetDiscount(state) {
      state.loading.discountIsLoading = false;
      state.discount = null;
      state.error.discountError = null;
    }
  }
});

// Reducer
export default slice.reducer;

export const { resetDiscounts, resetDiscount } = slice.actions;

// ----------------------------------------------------------------------

export function getDiscount(code) {
  return async (dispatch) => {
    dispatch(slice.actions.startDiscountLoading());
    try {
      const response = await axios.get(`/manage/discounts/${code}`);
      dispatch(slice.actions.getDiscountSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasDiscountError(error));
    }
  };
}

export function getDiscounts(page, rowsPerPage, orderBy, order, filterName) {
  return async (dispatch) => {
    dispatch(slice.actions.startDiscountsLoading());
    try {
      const response = await axios.get('/manage/discounts', {
        params: {
          page,
          size: rowsPerPage,
          orderBy,
          order,
          search: filterName
        }
      });
      dispatch(slice.actions.getDiscountsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasDiscountsError(error));
    }
  };
}

export function deleteDiscountById(id) {
  return axios.delete(`/manage/discounts/${id}`);
}

export function deleteDiscounts(ids) {
  return axios.post(`/manage/discounts/batch`, null, {
    params: {
      ids: ids.toString()
    }
  });
}

export async function createDiscount(values) {
  return axios.post('/manage/discounts', values);
}

export async function updateDiscount(code, values) {
  return axios.put(`/manage/discounts/${code}`, values);
}

export async function isDiscountUnique(code) {
  return axios.head(`/manage/discounts/${code}/validate`);
}
