import { Link as RouterLink, useLocation } from 'react-router-dom';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { MAvatar, MIconButton } from 'src/components/@material-extend';
import { ICONS } from './SidebarConfig';

// ----------------------------------------------------------------------

export default function SupportButton() {
  const { pathname } = useLocation();
  const isSupport = pathname?.includes('support');

  return (
    <MIconButton
      LinkComponent={RouterLink}
      to={PATH_DASHBOARD.support.root}
      disabled={isSupport}
      sx={{
        width: 44,
        height: 44
      }}
    >
      <MAvatar color={isSupport ? 'default' : 'warning'} sx={{ padding: (theme) => theme.spacing(1.2) }}>
        {ICONS.support}
      </MAvatar>
    </MIconButton>
  );
}
