import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import track from 'src/utils/analytics';

export default function GoogleAnalyticsTrackPage({ children }) {
  const trackPath = useRef(null);
  const { pathname } = useLocation();

  useEffect(() => {
    if (trackPath.current === pathname || !window.gtag) {
      return;
    }

    trackPath.current = pathname;
    track.pageview({
      page_path: pathname
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, window.gtag]);

  return children;
}
